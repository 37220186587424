/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BarTimeframe } from '../models';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { TerminalAccountSymbols } from '../models';
// @ts-ignore
import { TerminalBalanceOperationItemsContainer } from '../models';
// @ts-ignore
import { TerminalBarItemsContainer } from '../models';
// @ts-ignore
import { TerminalChartData } from '../models';
// @ts-ignore
import { TerminalChartMetaInfoItemsContainer } from '../models';
// @ts-ignore
import { TerminalChartUpdateData } from '../models';
// @ts-ignore
import { TerminalChartUpdateResult } from '../models';
// @ts-ignore
import { TerminalCloseOrderRequest } from '../models';
// @ts-ignore
import { TerminalIndicator } from '../models';
// @ts-ignore
import { TerminalIndicatorContainer } from '../models';
// @ts-ignore
import { TerminalModifyOrderRequest } from '../models';
// @ts-ignore
import { TerminalOpenOrderRequest } from '../models';
// @ts-ignore
import { TerminalOrderItemsContainer } from '../models';
// @ts-ignore
import { TerminalOrderSorting } from '../models';
// @ts-ignore
import { TerminalPositionItemsContainer } from '../models';
// @ts-ignore
import { TerminalStudyTemplateAddData } from '../models';
// @ts-ignore
import { TerminalStudyTemplateData } from '../models';
// @ts-ignore
import { TerminalStudyTemplateMetaInfoItemsContainer } from '../models';
// @ts-ignore
import { TerminalSymbols } from '../models';
// @ts-ignore
import { TerminalSymbolsUpdates } from '../models';
// @ts-ignore
import { TerminalTradingCentralArticleItemsContainer } from '../models';
// @ts-ignore
import { TerminalTradingCentralHandShake } from '../models';
// @ts-ignore
import { TerminalTradingCentralWidgetCredentials } from '../models';
/**
 * TerminalApi - axios parameter creator
 * @export
 */
export const TerminalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add last indicator
         * @param {string} tradingAccountId 
         * @param {TerminalIndicator} [terminalIndicator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLastIndicator: async (tradingAccountId: string, terminalIndicator?: TerminalIndicator, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addLastIndicator', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/indicators/last/add`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalIndicator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update chart data
         * @param {string} tradingAccountId 
         * @param {TerminalChartUpdateData} [terminalChartUpdateData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateChartData: async (tradingAccountId: string, terminalChartUpdateData?: TerminalChartUpdateData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addOrUpdateChartData', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/charts/update`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalChartUpdateData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add study template data
         * @param {string} tradingAccountId 
         * @param {TerminalStudyTemplateAddData} [terminalStudyTemplateAddData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudyTemplateData: async (tradingAccountId: string, terminalStudyTemplateAddData?: TerminalStudyTemplateAddData, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addStudyTemplateData', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/studytemplates/add`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalStudyTemplateAddData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add symbol to charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSymbolToCharts: async (tradingAccountId: string, symbol: string, isReplace?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addSymbolToCharts', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('addSymbolToCharts', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/charts/{symbol}/add`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isReplace !== undefined) {
                localVarQueryParameter['isReplace'] = isReplace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add symbol to favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSymbolToFavorites: async (tradingAccountId: string, symbol: string, isReplace?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('addSymbolToFavorites', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('addSymbolToFavorites', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/favorites/{symbol}/add`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isReplace !== undefined) {
                localVarQueryParameter['isReplace'] = isReplace;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Balance operations history
         * @param {string} tradingAccountId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceOperationsHistory: async (tradingAccountId: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('balanceOperationsHistory', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/balance`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Close orders
         * @param {string} tradingAccountId 
         * @param {TerminalCloseOrderRequest} [terminalCloseOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeOrders: async (tradingAccountId: string, terminalCloseOrderRequest?: TerminalCloseOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('closeOrders', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/orders/close`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalCloseOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User server symbols
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSymbols: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getAccountSymbols', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/favorites`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartData: async (tradingAccountId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getChartData', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getChartData', 'id', id)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/charts/{id}`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all charts meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartsMetaInfo: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getChartsMetaInfo', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/charts`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get last indicator
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastIndicator: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getLastIndicator', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/indicators/last`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyTemplateData: async (tradingAccountId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getStudyTemplateData', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getStudyTemplateData', 'name', name)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/studytemplates/{name}`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all study templates meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyTemplatesMetaInfo: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getStudyTemplatesMetaInfo', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/studytemplates`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Symbol charts
         * @param {string} tradingServerId Tradings server id
         * @param {string} symbol Symbol name
         * @param {BarTimeframe} [timeframe] Timeframe
         * @param {string} [from] Date from
         * @param {string} [to] Date to
         * @param {number} [countBack] The exact amount of bars to load
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbolCharts: async (tradingServerId: string, symbol: string, timeframe?: BarTimeframe, from?: string, to?: string, countBack?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingServerId' is not null or undefined
            assertParamExists('getSymbolCharts', 'tradingServerId', tradingServerId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('getSymbolCharts', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/{tradingServerId}/symbols/{symbol}/chart`
                .replace(`{${"tradingServerId"}}`, encodeURIComponent(String(tradingServerId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (timeframe !== undefined) {
                localVarQueryParameter['timeframe'] = timeframe;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (countBack !== undefined) {
                localVarQueryParameter['countBack'] = countBack;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Server symbols
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbols: async (tradingServerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingServerId' is not null or undefined
            assertParamExists('getSymbols', 'tradingServerId', tradingServerId)
            const localVarPath = `/v1.1/terminal/{tradingServerId}/symbols`
                .replace(`{${"tradingServerId"}}`, encodeURIComponent(String(tradingServerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Server symbols updates
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbolsUpdates: async (tradingServerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingServerId' is not null or undefined
            assertParamExists('getSymbolsUpdates', 'tradingServerId', tradingServerId)
            const localVarPath = `/v1.0/terminal/{tradingServerId}/symbols/updates`
                .replace(`{${"tradingServerId"}}`, encodeURIComponent(String(tradingServerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create account WS token
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAccountToken: async (tradingAccountId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('getTerminalAccountToken', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/token`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get TradingCentral Calendar credentials
         * @param {TerminalTradingCentralHandShake} [terminalTradingCentralHandShake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralCalendarCredentials: async (terminalTradingCentralHandShake?: TerminalTradingCentralHandShake, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/terminal/widget/trading-central/calendar/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalTradingCentralHandShake, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get TradingCentral News
         * @param {string} symbol 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralNews: async (symbol: string, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('getTradingCentralNews', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/widget/trading-central/news/{symbol}`
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get TradingCentral credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralWidgetCredentials: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/terminal/widget/trading-central/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify order/position
         * @param {string} tradingAccountId 
         * @param {number} id 
         * @param {TerminalModifyOrderRequest} [terminalModifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOrder: async (tradingAccountId: string, id: number, terminalModifyOrderRequest?: TerminalModifyOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('modifyOrder', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modifyOrder', 'id', id)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/orders/{id}/modify`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalModifyOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Open order
         * @param {string} tradingAccountId 
         * @param {TerminalOpenOrderRequest} [terminalOpenOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openOrder: async (tradingAccountId: string, terminalOpenOrderRequest?: TerminalOpenOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('openOrder', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/orders/open`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminalOpenOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Orders history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersHistory: async (tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('ordersHistory', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/orders`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Positions history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionsHistory: async (tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('positionsHistory', 'tradingAccountId', tradingAccountId)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/positions`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['From'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['To'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }

            if (symbol !== undefined) {
                localVarQueryParameter['Symbol'] = symbol;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['Sorting'] = sorting;
            }

            if (skip !== undefined) {
                localVarQueryParameter['Skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['Take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeChartData: async (tradingAccountId: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('removeChartData', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeChartData', 'id', id)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/charts/{id}/remove`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStudyTemplateData: async (tradingAccountId: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('removeStudyTemplateData', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('removeStudyTemplateData', 'name', name)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/studytemplates/{name}/remove`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove symbol from charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSymbolFromCharts: async (tradingAccountId: string, symbol: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('removeSymbolFromCharts', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('removeSymbolFromCharts', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/charts/{symbol}/remove`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove symbol from favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSymbolFromFavorites: async (tradingAccountId: string, symbol: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('removeSymbolFromFavorites', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('removeSymbolFromFavorites', 'symbol', symbol)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/favorites/{symbol}/remove`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"symbol"}}`, encodeURIComponent(String(symbol)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update favorite symbols group
         * @param {string} tradingAccountId 
         * @param {string} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteSymbolsGroup: async (tradingAccountId: string, group: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradingAccountId' is not null or undefined
            assertParamExists('updateFavoriteSymbolsGroup', 'tradingAccountId', tradingAccountId)
            // verify required parameter 'group' is not null or undefined
            assertParamExists('updateFavoriteSymbolsGroup', 'group', group)
            const localVarPath = `/v1.0/terminal/accounts/{tradingAccountId}/symbols/groups/{group}/update`
                .replace(`{${"tradingAccountId"}}`, encodeURIComponent(String(tradingAccountId)))
                .replace(`{${"group"}}`, encodeURIComponent(String(group)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TerminalApi - functional programming interface
 * @export
 */
export const TerminalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TerminalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add last indicator
         * @param {string} tradingAccountId 
         * @param {TerminalIndicator} [terminalIndicator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLastIndicator(tradingAccountId: string, terminalIndicator?: TerminalIndicator, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLastIndicator(tradingAccountId, terminalIndicator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update chart data
         * @param {string} tradingAccountId 
         * @param {TerminalChartUpdateData} [terminalChartUpdateData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateChartData(tradingAccountId: string, terminalChartUpdateData?: TerminalChartUpdateData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalChartUpdateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateChartData(tradingAccountId, terminalChartUpdateData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add study template data
         * @param {string} tradingAccountId 
         * @param {TerminalStudyTemplateAddData} [terminalStudyTemplateAddData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addStudyTemplateData(tradingAccountId: string, terminalStudyTemplateAddData?: TerminalStudyTemplateAddData, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addStudyTemplateData(tradingAccountId, terminalStudyTemplateAddData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add symbol to charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSymbolToCharts(tradingAccountId: string, symbol: string, isReplace?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSymbolToCharts(tradingAccountId, symbol, isReplace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add symbol to favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSymbolToFavorites(tradingAccountId: string, symbol: string, isReplace?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSymbolToFavorites(tradingAccountId, symbol, isReplace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Balance operations history
         * @param {string} tradingAccountId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async balanceOperationsHistory(tradingAccountId: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalBalanceOperationItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.balanceOperationsHistory(tradingAccountId, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Close orders
         * @param {string} tradingAccountId 
         * @param {TerminalCloseOrderRequest} [terminalCloseOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeOrders(tradingAccountId: string, terminalCloseOrderRequest?: TerminalCloseOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeOrders(tradingAccountId, terminalCloseOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User server symbols
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountSymbols(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalAccountSymbols>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountSymbols(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartData(tradingAccountId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalChartData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartData(tradingAccountId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all charts meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChartsMetaInfo(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalChartMetaInfoItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChartsMetaInfo(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get last indicator
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastIndicator(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalIndicatorContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastIndicator(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyTemplateData(tradingAccountId: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalStudyTemplateData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyTemplateData(tradingAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all study templates meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudyTemplatesMetaInfo(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalStudyTemplateMetaInfoItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudyTemplatesMetaInfo(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Symbol charts
         * @param {string} tradingServerId Tradings server id
         * @param {string} symbol Symbol name
         * @param {BarTimeframe} [timeframe] Timeframe
         * @param {string} [from] Date from
         * @param {string} [to] Date to
         * @param {number} [countBack] The exact amount of bars to load
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSymbolCharts(tradingServerId: string, symbol: string, timeframe?: BarTimeframe, from?: string, to?: string, countBack?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalBarItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSymbolCharts(tradingServerId, symbol, timeframe, from, to, countBack, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Server symbols
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSymbols(tradingServerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalSymbols>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSymbols(tradingServerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Server symbols updates
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSymbolsUpdates(tradingServerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalSymbolsUpdates>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSymbolsUpdates(tradingServerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create account WS token
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerminalAccountToken(tradingAccountId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerminalAccountToken(tradingAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get TradingCentral Calendar credentials
         * @param {TerminalTradingCentralHandShake} [terminalTradingCentralHandShake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingCentralCalendarCredentials(terminalTradingCentralHandShake?: TerminalTradingCentralHandShake, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalTradingCentralWidgetCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingCentralCalendarCredentials(terminalTradingCentralHandShake, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get TradingCentral News
         * @param {string} symbol 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingCentralNews(symbol: string, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalTradingCentralArticleItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingCentralNews(symbol, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get TradingCentral credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradingCentralWidgetCredentials(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalTradingCentralWidgetCredentials>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradingCentralWidgetCredentials(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Modify order/position
         * @param {string} tradingAccountId 
         * @param {number} id 
         * @param {TerminalModifyOrderRequest} [terminalModifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyOrder(tradingAccountId: string, id: number, terminalModifyOrderRequest?: TerminalModifyOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyOrder(tradingAccountId, id, terminalModifyOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Open order
         * @param {string} tradingAccountId 
         * @param {TerminalOpenOrderRequest} [terminalOpenOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openOrder(tradingAccountId: string, terminalOpenOrderRequest?: TerminalOpenOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openOrder(tradingAccountId, terminalOpenOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Orders history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordersHistory(tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalOrderItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordersHistory(tradingAccountId, from, to, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Positions history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async positionsHistory(tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TerminalPositionItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.positionsHistory(tradingAccountId, from, to, symbol, sorting, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeChartData(tradingAccountId: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeChartData(tradingAccountId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeStudyTemplateData(tradingAccountId: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeStudyTemplateData(tradingAccountId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove symbol from charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSymbolFromCharts(tradingAccountId: string, symbol: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSymbolFromCharts(tradingAccountId, symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove symbol from favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSymbolFromFavorites(tradingAccountId: string, symbol: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSymbolFromFavorites(tradingAccountId, symbol, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update favorite symbols group
         * @param {string} tradingAccountId 
         * @param {string} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFavoriteSymbolsGroup(tradingAccountId: string, group: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFavoriteSymbolsGroup(tradingAccountId, group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TerminalApi - factory interface
 * @export
 */
export const TerminalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TerminalApiFp(configuration)
    return {
        /**
         * 
         * @summary Add last indicator
         * @param {string} tradingAccountId 
         * @param {TerminalIndicator} [terminalIndicator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLastIndicator(tradingAccountId: string, terminalIndicator?: TerminalIndicator, options?: any): AxiosPromise<void> {
            return localVarFp.addLastIndicator(tradingAccountId, terminalIndicator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update chart data
         * @param {string} tradingAccountId 
         * @param {TerminalChartUpdateData} [terminalChartUpdateData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateChartData(tradingAccountId: string, terminalChartUpdateData?: TerminalChartUpdateData, options?: any): AxiosPromise<TerminalChartUpdateResult> {
            return localVarFp.addOrUpdateChartData(tradingAccountId, terminalChartUpdateData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add study template data
         * @param {string} tradingAccountId 
         * @param {TerminalStudyTemplateAddData} [terminalStudyTemplateAddData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudyTemplateData(tradingAccountId: string, terminalStudyTemplateAddData?: TerminalStudyTemplateAddData, options?: any): AxiosPromise<void> {
            return localVarFp.addStudyTemplateData(tradingAccountId, terminalStudyTemplateAddData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add symbol to charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSymbolToCharts(tradingAccountId: string, symbol: string, isReplace?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.addSymbolToCharts(tradingAccountId, symbol, isReplace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add symbol to favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {boolean} [isReplace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSymbolToFavorites(tradingAccountId: string, symbol: string, isReplace?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.addSymbolToFavorites(tradingAccountId, symbol, isReplace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Balance operations history
         * @param {string} tradingAccountId 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceOperationsHistory(tradingAccountId: string, skip?: number, take?: number, options?: any): AxiosPromise<TerminalBalanceOperationItemsContainer> {
            return localVarFp.balanceOperationsHistory(tradingAccountId, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Close orders
         * @param {string} tradingAccountId 
         * @param {TerminalCloseOrderRequest} [terminalCloseOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeOrders(tradingAccountId: string, terminalCloseOrderRequest?: TerminalCloseOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.closeOrders(tradingAccountId, terminalCloseOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User server symbols
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountSymbols(tradingAccountId: string, options?: any): AxiosPromise<TerminalAccountSymbols> {
            return localVarFp.getAccountSymbols(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartData(tradingAccountId: string, id: string, options?: any): AxiosPromise<TerminalChartData> {
            return localVarFp.getChartData(tradingAccountId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all charts meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChartsMetaInfo(tradingAccountId: string, options?: any): AxiosPromise<TerminalChartMetaInfoItemsContainer> {
            return localVarFp.getChartsMetaInfo(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get last indicator
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastIndicator(tradingAccountId: string, options?: any): AxiosPromise<TerminalIndicatorContainer> {
            return localVarFp.getLastIndicator(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyTemplateData(tradingAccountId: string, name: string, options?: any): AxiosPromise<TerminalStudyTemplateData> {
            return localVarFp.getStudyTemplateData(tradingAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all study templates meta info
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyTemplatesMetaInfo(tradingAccountId: string, options?: any): AxiosPromise<TerminalStudyTemplateMetaInfoItemsContainer> {
            return localVarFp.getStudyTemplatesMetaInfo(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Symbol charts
         * @param {string} tradingServerId Tradings server id
         * @param {string} symbol Symbol name
         * @param {BarTimeframe} [timeframe] Timeframe
         * @param {string} [from] Date from
         * @param {string} [to] Date to
         * @param {number} [countBack] The exact amount of bars to load
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbolCharts(tradingServerId: string, symbol: string, timeframe?: BarTimeframe, from?: string, to?: string, countBack?: number, options?: any): AxiosPromise<TerminalBarItemsContainer> {
            return localVarFp.getSymbolCharts(tradingServerId, symbol, timeframe, from, to, countBack, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Server symbols
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbols(tradingServerId: string, options?: any): AxiosPromise<TerminalSymbols> {
            return localVarFp.getSymbols(tradingServerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Server symbols updates
         * @param {string} tradingServerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSymbolsUpdates(tradingServerId: string, options?: any): AxiosPromise<TerminalSymbolsUpdates> {
            return localVarFp.getSymbolsUpdates(tradingServerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create account WS token
         * @param {string} tradingAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerminalAccountToken(tradingAccountId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getTerminalAccountToken(tradingAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get TradingCentral Calendar credentials
         * @param {TerminalTradingCentralHandShake} [terminalTradingCentralHandShake] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralCalendarCredentials(terminalTradingCentralHandShake?: TerminalTradingCentralHandShake, options?: any): AxiosPromise<TerminalTradingCentralWidgetCredentials> {
            return localVarFp.getTradingCentralCalendarCredentials(terminalTradingCentralHandShake, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get TradingCentral News
         * @param {string} symbol 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralNews(symbol: string, skip?: number, take?: number, options?: any): AxiosPromise<TerminalTradingCentralArticleItemsContainer> {
            return localVarFp.getTradingCentralNews(symbol, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get TradingCentral credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradingCentralWidgetCredentials(options?: any): AxiosPromise<TerminalTradingCentralWidgetCredentials> {
            return localVarFp.getTradingCentralWidgetCredentials(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Modify order/position
         * @param {string} tradingAccountId 
         * @param {number} id 
         * @param {TerminalModifyOrderRequest} [terminalModifyOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOrder(tradingAccountId: string, id: number, terminalModifyOrderRequest?: TerminalModifyOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.modifyOrder(tradingAccountId, id, terminalModifyOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Open order
         * @param {string} tradingAccountId 
         * @param {TerminalOpenOrderRequest} [terminalOpenOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openOrder(tradingAccountId: string, terminalOpenOrderRequest?: TerminalOpenOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.openOrder(tradingAccountId, terminalOpenOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Orders history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordersHistory(tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options?: any): AxiosPromise<TerminalOrderItemsContainer> {
            return localVarFp.ordersHistory(tradingAccountId, from, to, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Positions history
         * @param {string} tradingAccountId 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {string} [symbol] 
         * @param {TerminalOrderSorting} [sorting] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        positionsHistory(tradingAccountId: string, from?: string, to?: string, symbol?: string, sorting?: TerminalOrderSorting, skip?: number, take?: number, options?: any): AxiosPromise<TerminalPositionItemsContainer> {
            return localVarFp.positionsHistory(tradingAccountId, from, to, symbol, sorting, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove chart data
         * @param {string} tradingAccountId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeChartData(tradingAccountId: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeChartData(tradingAccountId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove study template data
         * @param {string} tradingAccountId 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeStudyTemplateData(tradingAccountId: string, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeStudyTemplateData(tradingAccountId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove symbol from charts
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSymbolFromCharts(tradingAccountId: string, symbol: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeSymbolFromCharts(tradingAccountId, symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove symbol from favorites
         * @param {string} tradingAccountId 
         * @param {string} symbol 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSymbolFromFavorites(tradingAccountId: string, symbol: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeSymbolFromFavorites(tradingAccountId, symbol, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update favorite symbols group
         * @param {string} tradingAccountId 
         * @param {string} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFavoriteSymbolsGroup(tradingAccountId: string, group: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateFavoriteSymbolsGroup(tradingAccountId, group, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addLastIndicator operation in TerminalApi.
 * @export
 * @interface TerminalApiAddLastIndicatorRequest
 */
export interface TerminalApiAddLastIndicatorRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddLastIndicator
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalIndicator}
     * @memberof TerminalApiAddLastIndicator
     */
    readonly terminalIndicator?: TerminalIndicator
}

/**
 * Request parameters for addOrUpdateChartData operation in TerminalApi.
 * @export
 * @interface TerminalApiAddOrUpdateChartDataRequest
 */
export interface TerminalApiAddOrUpdateChartDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddOrUpdateChartData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalChartUpdateData}
     * @memberof TerminalApiAddOrUpdateChartData
     */
    readonly terminalChartUpdateData?: TerminalChartUpdateData
}

/**
 * Request parameters for addStudyTemplateData operation in TerminalApi.
 * @export
 * @interface TerminalApiAddStudyTemplateDataRequest
 */
export interface TerminalApiAddStudyTemplateDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddStudyTemplateData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalStudyTemplateAddData}
     * @memberof TerminalApiAddStudyTemplateData
     */
    readonly terminalStudyTemplateAddData?: TerminalStudyTemplateAddData
}

/**
 * Request parameters for addSymbolToCharts operation in TerminalApi.
 * @export
 * @interface TerminalApiAddSymbolToChartsRequest
 */
export interface TerminalApiAddSymbolToChartsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddSymbolToCharts
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddSymbolToCharts
     */
    readonly symbol: string

    /**
     * 
     * @type {boolean}
     * @memberof TerminalApiAddSymbolToCharts
     */
    readonly isReplace?: boolean
}

/**
 * Request parameters for addSymbolToFavorites operation in TerminalApi.
 * @export
 * @interface TerminalApiAddSymbolToFavoritesRequest
 */
export interface TerminalApiAddSymbolToFavoritesRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddSymbolToFavorites
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiAddSymbolToFavorites
     */
    readonly symbol: string

    /**
     * 
     * @type {boolean}
     * @memberof TerminalApiAddSymbolToFavorites
     */
    readonly isReplace?: boolean
}

/**
 * Request parameters for balanceOperationsHistory operation in TerminalApi.
 * @export
 * @interface TerminalApiBalanceOperationsHistoryRequest
 */
export interface TerminalApiBalanceOperationsHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiBalanceOperationsHistory
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {number}
     * @memberof TerminalApiBalanceOperationsHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TerminalApiBalanceOperationsHistory
     */
    readonly take?: number
}

/**
 * Request parameters for closeOrders operation in TerminalApi.
 * @export
 * @interface TerminalApiCloseOrdersRequest
 */
export interface TerminalApiCloseOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiCloseOrders
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalCloseOrderRequest}
     * @memberof TerminalApiCloseOrders
     */
    readonly terminalCloseOrderRequest?: TerminalCloseOrderRequest
}

/**
 * Request parameters for getAccountSymbols operation in TerminalApi.
 * @export
 * @interface TerminalApiGetAccountSymbolsRequest
 */
export interface TerminalApiGetAccountSymbolsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetAccountSymbols
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getChartData operation in TerminalApi.
 * @export
 * @interface TerminalApiGetChartDataRequest
 */
export interface TerminalApiGetChartDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetChartData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetChartData
     */
    readonly id: string
}

/**
 * Request parameters for getChartsMetaInfo operation in TerminalApi.
 * @export
 * @interface TerminalApiGetChartsMetaInfoRequest
 */
export interface TerminalApiGetChartsMetaInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetChartsMetaInfo
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getLastIndicator operation in TerminalApi.
 * @export
 * @interface TerminalApiGetLastIndicatorRequest
 */
export interface TerminalApiGetLastIndicatorRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetLastIndicator
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getStudyTemplateData operation in TerminalApi.
 * @export
 * @interface TerminalApiGetStudyTemplateDataRequest
 */
export interface TerminalApiGetStudyTemplateDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetStudyTemplateData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetStudyTemplateData
     */
    readonly name: string
}

/**
 * Request parameters for getStudyTemplatesMetaInfo operation in TerminalApi.
 * @export
 * @interface TerminalApiGetStudyTemplatesMetaInfoRequest
 */
export interface TerminalApiGetStudyTemplatesMetaInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetStudyTemplatesMetaInfo
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getSymbolCharts operation in TerminalApi.
 * @export
 * @interface TerminalApiGetSymbolChartsRequest
 */
export interface TerminalApiGetSymbolChartsRequest {
    /**
     * Tradings server id
     * @type {string}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly tradingServerId: string

    /**
     * Symbol name
     * @type {string}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly symbol: string

    /**
     * Timeframe
     * @type {BarTimeframe}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly timeframe?: BarTimeframe

    /**
     * Date from
     * @type {string}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly from?: string

    /**
     * Date to
     * @type {string}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly to?: string

    /**
     * The exact amount of bars to load
     * @type {number}
     * @memberof TerminalApiGetSymbolCharts
     */
    readonly countBack?: number
}

/**
 * Request parameters for getSymbols operation in TerminalApi.
 * @export
 * @interface TerminalApiGetSymbolsRequest
 */
export interface TerminalApiGetSymbolsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetSymbols
     */
    readonly tradingServerId: string
}

/**
 * Request parameters for getSymbolsUpdates operation in TerminalApi.
 * @export
 * @interface TerminalApiGetSymbolsUpdatesRequest
 */
export interface TerminalApiGetSymbolsUpdatesRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetSymbolsUpdates
     */
    readonly tradingServerId: string
}

/**
 * Request parameters for getTerminalAccountToken operation in TerminalApi.
 * @export
 * @interface TerminalApiGetTerminalAccountTokenRequest
 */
export interface TerminalApiGetTerminalAccountTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetTerminalAccountToken
     */
    readonly tradingAccountId: string
}

/**
 * Request parameters for getTradingCentralCalendarCredentials operation in TerminalApi.
 * @export
 * @interface TerminalApiGetTradingCentralCalendarCredentialsRequest
 */
export interface TerminalApiGetTradingCentralCalendarCredentialsRequest {
    /**
     * 
     * @type {TerminalTradingCentralHandShake}
     * @memberof TerminalApiGetTradingCentralCalendarCredentials
     */
    readonly terminalTradingCentralHandShake?: TerminalTradingCentralHandShake
}

/**
 * Request parameters for getTradingCentralNews operation in TerminalApi.
 * @export
 * @interface TerminalApiGetTradingCentralNewsRequest
 */
export interface TerminalApiGetTradingCentralNewsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiGetTradingCentralNews
     */
    readonly symbol: string

    /**
     * 
     * @type {number}
     * @memberof TerminalApiGetTradingCentralNews
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TerminalApiGetTradingCentralNews
     */
    readonly take?: number
}

/**
 * Request parameters for modifyOrder operation in TerminalApi.
 * @export
 * @interface TerminalApiModifyOrderRequest
 */
export interface TerminalApiModifyOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiModifyOrder
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {number}
     * @memberof TerminalApiModifyOrder
     */
    readonly id: number

    /**
     * 
     * @type {TerminalModifyOrderRequest}
     * @memberof TerminalApiModifyOrder
     */
    readonly terminalModifyOrderRequest?: TerminalModifyOrderRequest
}

/**
 * Request parameters for openOrder operation in TerminalApi.
 * @export
 * @interface TerminalApiOpenOrderRequest
 */
export interface TerminalApiOpenOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiOpenOrder
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {TerminalOpenOrderRequest}
     * @memberof TerminalApiOpenOrder
     */
    readonly terminalOpenOrderRequest?: TerminalOpenOrderRequest
}

/**
 * Request parameters for ordersHistory operation in TerminalApi.
 * @export
 * @interface TerminalApiOrdersHistoryRequest
 */
export interface TerminalApiOrdersHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiOrdersHistory
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiOrdersHistory
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiOrdersHistory
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiOrdersHistory
     */
    readonly symbol?: string

    /**
     * 
     * @type {TerminalOrderSorting}
     * @memberof TerminalApiOrdersHistory
     */
    readonly sorting?: TerminalOrderSorting

    /**
     * 
     * @type {number}
     * @memberof TerminalApiOrdersHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TerminalApiOrdersHistory
     */
    readonly take?: number
}

/**
 * Request parameters for positionsHistory operation in TerminalApi.
 * @export
 * @interface TerminalApiPositionsHistoryRequest
 */
export interface TerminalApiPositionsHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiPositionsHistory
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiPositionsHistory
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiPositionsHistory
     */
    readonly to?: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiPositionsHistory
     */
    readonly symbol?: string

    /**
     * 
     * @type {TerminalOrderSorting}
     * @memberof TerminalApiPositionsHistory
     */
    readonly sorting?: TerminalOrderSorting

    /**
     * 
     * @type {number}
     * @memberof TerminalApiPositionsHistory
     */
    readonly skip?: number

    /**
     * 
     * @type {number}
     * @memberof TerminalApiPositionsHistory
     */
    readonly take?: number
}

/**
 * Request parameters for removeChartData operation in TerminalApi.
 * @export
 * @interface TerminalApiRemoveChartDataRequest
 */
export interface TerminalApiRemoveChartDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveChartData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveChartData
     */
    readonly id: string
}

/**
 * Request parameters for removeStudyTemplateData operation in TerminalApi.
 * @export
 * @interface TerminalApiRemoveStudyTemplateDataRequest
 */
export interface TerminalApiRemoveStudyTemplateDataRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveStudyTemplateData
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveStudyTemplateData
     */
    readonly name: string
}

/**
 * Request parameters for removeSymbolFromCharts operation in TerminalApi.
 * @export
 * @interface TerminalApiRemoveSymbolFromChartsRequest
 */
export interface TerminalApiRemoveSymbolFromChartsRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveSymbolFromCharts
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveSymbolFromCharts
     */
    readonly symbol: string
}

/**
 * Request parameters for removeSymbolFromFavorites operation in TerminalApi.
 * @export
 * @interface TerminalApiRemoveSymbolFromFavoritesRequest
 */
export interface TerminalApiRemoveSymbolFromFavoritesRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveSymbolFromFavorites
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiRemoveSymbolFromFavorites
     */
    readonly symbol: string
}

/**
 * Request parameters for updateFavoriteSymbolsGroup operation in TerminalApi.
 * @export
 * @interface TerminalApiUpdateFavoriteSymbolsGroupRequest
 */
export interface TerminalApiUpdateFavoriteSymbolsGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminalApiUpdateFavoriteSymbolsGroup
     */
    readonly tradingAccountId: string

    /**
     * 
     * @type {string}
     * @memberof TerminalApiUpdateFavoriteSymbolsGroup
     */
    readonly group: string
}

/**
 * TerminalApi - object-oriented interface
 * @export
 * @class TerminalApi
 * @extends {BaseAPI}
 */
export class TerminalApi extends BaseAPI {
    /**
     * 
     * @summary Add last indicator
     * @param {TerminalApiAddLastIndicatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addLastIndicator(requestParameters: TerminalApiAddLastIndicatorRequest, options?: any) {
        return TerminalApiFp(this.configuration).addLastIndicator(requestParameters.tradingAccountId, requestParameters.terminalIndicator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update chart data
     * @param {TerminalApiAddOrUpdateChartDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addOrUpdateChartData(requestParameters: TerminalApiAddOrUpdateChartDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).addOrUpdateChartData(requestParameters.tradingAccountId, requestParameters.terminalChartUpdateData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add study template data
     * @param {TerminalApiAddStudyTemplateDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addStudyTemplateData(requestParameters: TerminalApiAddStudyTemplateDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).addStudyTemplateData(requestParameters.tradingAccountId, requestParameters.terminalStudyTemplateAddData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add symbol to charts
     * @param {TerminalApiAddSymbolToChartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addSymbolToCharts(requestParameters: TerminalApiAddSymbolToChartsRequest, options?: any) {
        return TerminalApiFp(this.configuration).addSymbolToCharts(requestParameters.tradingAccountId, requestParameters.symbol, requestParameters.isReplace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add symbol to favorites
     * @param {TerminalApiAddSymbolToFavoritesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public addSymbolToFavorites(requestParameters: TerminalApiAddSymbolToFavoritesRequest, options?: any) {
        return TerminalApiFp(this.configuration).addSymbolToFavorites(requestParameters.tradingAccountId, requestParameters.symbol, requestParameters.isReplace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Balance operations history
     * @param {TerminalApiBalanceOperationsHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public balanceOperationsHistory(requestParameters: TerminalApiBalanceOperationsHistoryRequest, options?: any) {
        return TerminalApiFp(this.configuration).balanceOperationsHistory(requestParameters.tradingAccountId, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Close orders
     * @param {TerminalApiCloseOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public closeOrders(requestParameters: TerminalApiCloseOrdersRequest, options?: any) {
        return TerminalApiFp(this.configuration).closeOrders(requestParameters.tradingAccountId, requestParameters.terminalCloseOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User server symbols
     * @param {TerminalApiGetAccountSymbolsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getAccountSymbols(requestParameters: TerminalApiGetAccountSymbolsRequest, options?: any) {
        return TerminalApiFp(this.configuration).getAccountSymbols(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get chart data
     * @param {TerminalApiGetChartDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getChartData(requestParameters: TerminalApiGetChartDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).getChartData(requestParameters.tradingAccountId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all charts meta info
     * @param {TerminalApiGetChartsMetaInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getChartsMetaInfo(requestParameters: TerminalApiGetChartsMetaInfoRequest, options?: any) {
        return TerminalApiFp(this.configuration).getChartsMetaInfo(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get last indicator
     * @param {TerminalApiGetLastIndicatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getLastIndicator(requestParameters: TerminalApiGetLastIndicatorRequest, options?: any) {
        return TerminalApiFp(this.configuration).getLastIndicator(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get study template data
     * @param {TerminalApiGetStudyTemplateDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getStudyTemplateData(requestParameters: TerminalApiGetStudyTemplateDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).getStudyTemplateData(requestParameters.tradingAccountId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all study templates meta info
     * @param {TerminalApiGetStudyTemplatesMetaInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getStudyTemplatesMetaInfo(requestParameters: TerminalApiGetStudyTemplatesMetaInfoRequest, options?: any) {
        return TerminalApiFp(this.configuration).getStudyTemplatesMetaInfo(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Symbol charts
     * @param {TerminalApiGetSymbolChartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getSymbolCharts(requestParameters: TerminalApiGetSymbolChartsRequest, options?: any) {
        return TerminalApiFp(this.configuration).getSymbolCharts(requestParameters.tradingServerId, requestParameters.symbol, requestParameters.timeframe, requestParameters.from, requestParameters.to, requestParameters.countBack, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Server symbols
     * @param {TerminalApiGetSymbolsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getSymbols(requestParameters: TerminalApiGetSymbolsRequest, options?: any) {
        return TerminalApiFp(this.configuration).getSymbols(requestParameters.tradingServerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Server symbols updates
     * @param {TerminalApiGetSymbolsUpdatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getSymbolsUpdates(requestParameters: TerminalApiGetSymbolsUpdatesRequest, options?: any) {
        return TerminalApiFp(this.configuration).getSymbolsUpdates(requestParameters.tradingServerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create account WS token
     * @param {TerminalApiGetTerminalAccountTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getTerminalAccountToken(requestParameters: TerminalApiGetTerminalAccountTokenRequest, options?: any) {
        return TerminalApiFp(this.configuration).getTerminalAccountToken(requestParameters.tradingAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get TradingCentral Calendar credentials
     * @param {TerminalApiGetTradingCentralCalendarCredentialsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getTradingCentralCalendarCredentials(requestParameters: TerminalApiGetTradingCentralCalendarCredentialsRequest = {}, options?: any) {
        return TerminalApiFp(this.configuration).getTradingCentralCalendarCredentials(requestParameters.terminalTradingCentralHandShake, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get TradingCentral News
     * @param {TerminalApiGetTradingCentralNewsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getTradingCentralNews(requestParameters: TerminalApiGetTradingCentralNewsRequest, options?: any) {
        return TerminalApiFp(this.configuration).getTradingCentralNews(requestParameters.symbol, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get TradingCentral credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public getTradingCentralWidgetCredentials(options?: any) {
        return TerminalApiFp(this.configuration).getTradingCentralWidgetCredentials(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Modify order/position
     * @param {TerminalApiModifyOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public modifyOrder(requestParameters: TerminalApiModifyOrderRequest, options?: any) {
        return TerminalApiFp(this.configuration).modifyOrder(requestParameters.tradingAccountId, requestParameters.id, requestParameters.terminalModifyOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Open order
     * @param {TerminalApiOpenOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public openOrder(requestParameters: TerminalApiOpenOrderRequest, options?: any) {
        return TerminalApiFp(this.configuration).openOrder(requestParameters.tradingAccountId, requestParameters.terminalOpenOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Orders history
     * @param {TerminalApiOrdersHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public ordersHistory(requestParameters: TerminalApiOrdersHistoryRequest, options?: any) {
        return TerminalApiFp(this.configuration).ordersHistory(requestParameters.tradingAccountId, requestParameters.from, requestParameters.to, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Positions history
     * @param {TerminalApiPositionsHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public positionsHistory(requestParameters: TerminalApiPositionsHistoryRequest, options?: any) {
        return TerminalApiFp(this.configuration).positionsHistory(requestParameters.tradingAccountId, requestParameters.from, requestParameters.to, requestParameters.symbol, requestParameters.sorting, requestParameters.skip, requestParameters.take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove chart data
     * @param {TerminalApiRemoveChartDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public removeChartData(requestParameters: TerminalApiRemoveChartDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).removeChartData(requestParameters.tradingAccountId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove study template data
     * @param {TerminalApiRemoveStudyTemplateDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public removeStudyTemplateData(requestParameters: TerminalApiRemoveStudyTemplateDataRequest, options?: any) {
        return TerminalApiFp(this.configuration).removeStudyTemplateData(requestParameters.tradingAccountId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove symbol from charts
     * @param {TerminalApiRemoveSymbolFromChartsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public removeSymbolFromCharts(requestParameters: TerminalApiRemoveSymbolFromChartsRequest, options?: any) {
        return TerminalApiFp(this.configuration).removeSymbolFromCharts(requestParameters.tradingAccountId, requestParameters.symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove symbol from favorites
     * @param {TerminalApiRemoveSymbolFromFavoritesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public removeSymbolFromFavorites(requestParameters: TerminalApiRemoveSymbolFromFavoritesRequest, options?: any) {
        return TerminalApiFp(this.configuration).removeSymbolFromFavorites(requestParameters.tradingAccountId, requestParameters.symbol, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update favorite symbols group
     * @param {TerminalApiUpdateFavoriteSymbolsGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TerminalApi
     */
    public updateFavoriteSymbolsGroup(requestParameters: TerminalApiUpdateFavoriteSymbolsGroupRequest, options?: any) {
        return TerminalApiFp(this.configuration).updateFavoriteSymbolsGroup(requestParameters.tradingAccountId, requestParameters.group, options).then((request) => request(this.axios, this.basePath));
    }
}
