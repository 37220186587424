/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { FeeResponse } from '../models';
// @ts-ignore
import { InterfaceItem } from '../models';
// @ts-ignore
import { MaximumLimitDescription } from '../models';
// @ts-ignore
import { PaymentInfo } from '../models';
// @ts-ignore
import { PaymentMethodItemsContainer } from '../models';
// @ts-ignore
import { PaymentMethods } from '../models';
// @ts-ignore
import { WithdrawRequestPayload } from '../models';
// @ts-ignore
import { WithdrawResult } from '../models';
/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate fee for deposit
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositFee: async (paymentMethodId?: number, accountId?: string, amount?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/fee/deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['PaymentMethodId'] = paymentMethodId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositWidgets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/deposit/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deposit settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumDepositAmount: async (accountId?: string, paymentMethodId?: number, recurringId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/deposit/limit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['paymentMethodId'] = paymentMethodId;
            }

            if (recurringId !== undefined) {
                localVarQueryParameter['recurringId'] = recurringId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get withdraw settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumWithdrawAmount: async (accountId?: string, paymentMethodId?: number, recurringId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/withdraw/limit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['paymentMethodId'] = paymentMethodId;
            }

            if (recurringId !== undefined) {
                localVarQueryParameter['recurringId'] = recurringId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get common payment info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get awailable deposit methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods: async (accountId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/method/deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate fee for withdraw
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawFee: async (paymentMethodId?: number, accountId?: string, amount?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/fee/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['PaymentMethodId'] = paymentMethodId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get awailable withdraw methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawMethods: async (accountId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.1/payments/method/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawWidgets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/withdraw/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make deposit on real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used and saved kind of payment info
         * @param {string} [amount] Amount to deposit
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {string} [id] Unique UUID; should be unique for every request  For the same operation, if the first request fails with an error, client should send second request with the same id
         * @param {boolean} [isBonusesRejected] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDeposit: async (paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, id?: string, isBonusesRejected?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['PaymentMethodId'] = paymentMethodId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (recurringId !== undefined) {
                localVarQueryParameter['RecurringId'] = recurringId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }

            if (deviceType !== undefined) {
                localVarQueryParameter['DeviceType'] = deviceType;
            }

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (isBonusesRejected !== undefined) {
                localVarQueryParameter['IsBonusesRejected'] = isBonusesRejected;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make withdraw from real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used/saved payment info (for example, bank card number)
         * @param {string} [amount] Amount to withdraw
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {WithdrawRequestPayload} [withdrawRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeWithdraw: async (paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, withdrawRequestPayload?: WithdrawRequestPayload, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/payments/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['PaymentMethodId'] = paymentMethodId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['AccountId'] = accountId;
            }

            if (recurringId !== undefined) {
                localVarQueryParameter['RecurringId'] = recurringId;
            }

            if (amount !== undefined) {
                localVarQueryParameter['Amount'] = amount;
            }

            if (deviceType !== undefined) {
                localVarQueryParameter['DeviceType'] = deviceType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(withdrawRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculate fee for deposit
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepositFee(paymentMethodId?: number, accountId?: string, amount?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepositFee(paymentMethodId, accountId, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepositWidgets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfaceItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepositWidgets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get deposit settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaximumDepositAmount(accountId?: string, paymentMethodId?: number, recurringId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaximumLimitDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaximumDepositAmount(accountId, paymentMethodId, recurringId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get withdraw settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaximumWithdrawAmount(accountId?: string, paymentMethodId?: number, recurringId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaximumLimitDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaximumWithdrawAmount(accountId, paymentMethodId, recurringId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get common payment info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get awailable deposit methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethods(accountId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodItemsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethods(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate fee for withdraw
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawFee(paymentMethodId?: number, accountId?: string, amount?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWithdrawFee(paymentMethodId, accountId, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get awailable withdraw methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawMethods(accountId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethods>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWithdrawMethods(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWithdrawWidgets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterfaceItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWithdrawWidgets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make deposit on real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used and saved kind of payment info
         * @param {string} [amount] Amount to deposit
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {string} [id] Unique UUID; should be unique for every request  For the same operation, if the first request fails with an error, client should send second request with the same id
         * @param {boolean} [isBonusesRejected] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeDeposit(paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, id?: string, isBonusesRejected?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeDeposit(paymentMethodId, accountId, recurringId, amount, deviceType, id, isBonusesRejected, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make withdraw from real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used/saved payment info (for example, bank card number)
         * @param {string} [amount] Amount to withdraw
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {WithdrawRequestPayload} [withdrawRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeWithdraw(paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, withdrawRequestPayload?: WithdrawRequestPayload, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeWithdraw(paymentMethodId, accountId, recurringId, amount, deviceType, withdrawRequestPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculate fee for deposit
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositFee(paymentMethodId?: number, accountId?: string, amount?: string, options?: any): AxiosPromise<FeeResponse> {
            return localVarFp.getDepositFee(paymentMethodId, accountId, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositWidgets(options?: any): AxiosPromise<InterfaceItem> {
            return localVarFp.getDepositWidgets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deposit settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumDepositAmount(accountId?: string, paymentMethodId?: number, recurringId?: string, options?: any): AxiosPromise<MaximumLimitDescription> {
            return localVarFp.getMaximumDepositAmount(accountId, paymentMethodId, recurringId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get withdraw settings
         * @param {string} [accountId] 
         * @param {number} [paymentMethodId] 
         * @param {string} [recurringId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumWithdrawAmount(accountId?: string, paymentMethodId?: number, recurringId?: string, options?: any): AxiosPromise<MaximumLimitDescription> {
            return localVarFp.getMaximumWithdrawAmount(accountId, paymentMethodId, recurringId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get common payment info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentInfo(options?: any): AxiosPromise<PaymentInfo> {
            return localVarFp.getPaymentInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get awailable deposit methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods(accountId?: string, options?: any): AxiosPromise<PaymentMethodItemsContainer> {
            return localVarFp.getPaymentMethods(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate fee for withdraw
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [amount] Amount to deposit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawFee(paymentMethodId?: number, accountId?: string, amount?: string, options?: any): AxiosPromise<FeeResponse> {
            return localVarFp.getWithdrawFee(paymentMethodId, accountId, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get awailable withdraw methods
         * @param {string} [accountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawMethods(accountId?: string, options?: any): AxiosPromise<PaymentMethods> {
            return localVarFp.getWithdrawMethods(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWithdrawWidgets(options?: any): AxiosPromise<InterfaceItem> {
            return localVarFp.getWithdrawWidgets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make deposit on real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used and saved kind of payment info
         * @param {string} [amount] Amount to deposit
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {string} [id] Unique UUID; should be unique for every request  For the same operation, if the first request fails with an error, client should send second request with the same id
         * @param {boolean} [isBonusesRejected] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeDeposit(paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, id?: string, isBonusesRejected?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.makeDeposit(paymentMethodId, accountId, recurringId, amount, deviceType, id, isBonusesRejected, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make withdraw from real account
         * @param {number} [paymentMethodId] Id of desired payment method
         * @param {string} [accountId] Id of user\&#39;s trading account
         * @param {string} [recurringId] Id of previously used/saved payment info (for example, bank card number)
         * @param {string} [amount] Amount to withdraw
         * @param {string} [deviceType] Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
         * @param {WithdrawRequestPayload} [withdrawRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeWithdraw(paymentMethodId?: number, accountId?: string, recurringId?: string, amount?: string, deviceType?: string, withdrawRequestPayload?: WithdrawRequestPayload, options?: any): AxiosPromise<WithdrawResult> {
            return localVarFp.makeWithdraw(paymentMethodId, accountId, recurringId, amount, deviceType, withdrawRequestPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDepositFee operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetDepositFeeRequest
 */
export interface PaymentsApiGetDepositFeeRequest {
    /**
     * Id of desired payment method
     * @type {number}
     * @memberof PaymentsApiGetDepositFee
     */
    readonly paymentMethodId?: number

    /**
     * Id of user\&#39;s trading account
     * @type {string}
     * @memberof PaymentsApiGetDepositFee
     */
    readonly accountId?: string

    /**
     * Amount to deposit
     * @type {string}
     * @memberof PaymentsApiGetDepositFee
     */
    readonly amount?: string
}

/**
 * Request parameters for getMaximumDepositAmount operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetMaximumDepositAmountRequest
 */
export interface PaymentsApiGetMaximumDepositAmountRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetMaximumDepositAmount
     */
    readonly accountId?: string

    /**
     * 
     * @type {number}
     * @memberof PaymentsApiGetMaximumDepositAmount
     */
    readonly paymentMethodId?: number

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetMaximumDepositAmount
     */
    readonly recurringId?: string
}

/**
 * Request parameters for getMaximumWithdrawAmount operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetMaximumWithdrawAmountRequest
 */
export interface PaymentsApiGetMaximumWithdrawAmountRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetMaximumWithdrawAmount
     */
    readonly accountId?: string

    /**
     * 
     * @type {number}
     * @memberof PaymentsApiGetMaximumWithdrawAmount
     */
    readonly paymentMethodId?: number

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetMaximumWithdrawAmount
     */
    readonly recurringId?: string
}

/**
 * Request parameters for getPaymentMethods operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetPaymentMethodsRequest
 */
export interface PaymentsApiGetPaymentMethodsRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetPaymentMethods
     */
    readonly accountId?: string
}

/**
 * Request parameters for getWithdrawFee operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetWithdrawFeeRequest
 */
export interface PaymentsApiGetWithdrawFeeRequest {
    /**
     * Id of desired payment method
     * @type {number}
     * @memberof PaymentsApiGetWithdrawFee
     */
    readonly paymentMethodId?: number

    /**
     * Id of user\&#39;s trading account
     * @type {string}
     * @memberof PaymentsApiGetWithdrawFee
     */
    readonly accountId?: string

    /**
     * Amount to deposit
     * @type {string}
     * @memberof PaymentsApiGetWithdrawFee
     */
    readonly amount?: string
}

/**
 * Request parameters for getWithdrawMethods operation in PaymentsApi.
 * @export
 * @interface PaymentsApiGetWithdrawMethodsRequest
 */
export interface PaymentsApiGetWithdrawMethodsRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiGetWithdrawMethods
     */
    readonly accountId?: string
}

/**
 * Request parameters for makeDeposit operation in PaymentsApi.
 * @export
 * @interface PaymentsApiMakeDepositRequest
 */
export interface PaymentsApiMakeDepositRequest {
    /**
     * Id of desired payment method
     * @type {number}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly paymentMethodId?: number

    /**
     * Id of user\&#39;s trading account
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly accountId?: string

    /**
     * Id of previously used and saved kind of payment info
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly recurringId?: string

    /**
     * Amount to deposit
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly amount?: string

    /**
     * Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly deviceType?: string

    /**
     * Unique UUID; should be unique for every request  For the same operation, if the first request fails with an error, client should send second request with the same id
     * @type {string}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly id?: string

    /**
     * 
     * @type {boolean}
     * @memberof PaymentsApiMakeDeposit
     */
    readonly isBonusesRejected?: boolean
}

/**
 * Request parameters for makeWithdraw operation in PaymentsApi.
 * @export
 * @interface PaymentsApiMakeWithdrawRequest
 */
export interface PaymentsApiMakeWithdrawRequest {
    /**
     * Id of desired payment method
     * @type {number}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly paymentMethodId?: number

    /**
     * Id of user\&#39;s trading account
     * @type {string}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly accountId?: string

    /**
     * Id of previously used/saved payment info (for example, bank card number)
     * @type {string}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly recurringId?: string

    /**
     * Amount to withdraw
     * @type {string}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly amount?: string

    /**
     * Device type: desktop/tablet/mobile  \&quot;desktop\&quot; should be used for web
     * @type {string}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly deviceType?: string

    /**
     * 
     * @type {WithdrawRequestPayload}
     * @memberof PaymentsApiMakeWithdraw
     */
    readonly withdrawRequestPayload?: WithdrawRequestPayload
}

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @summary Calculate fee for deposit
     * @param {PaymentsApiGetDepositFeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getDepositFee(requestParameters: PaymentsApiGetDepositFeeRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getDepositFee(requestParameters.paymentMethodId, requestParameters.accountId, requestParameters.amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getDepositWidgets(options?: any) {
        return PaymentsApiFp(this.configuration).getDepositWidgets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deposit settings
     * @param {PaymentsApiGetMaximumDepositAmountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getMaximumDepositAmount(requestParameters: PaymentsApiGetMaximumDepositAmountRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getMaximumDepositAmount(requestParameters.accountId, requestParameters.paymentMethodId, requestParameters.recurringId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get withdraw settings
     * @param {PaymentsApiGetMaximumWithdrawAmountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getMaximumWithdrawAmount(requestParameters: PaymentsApiGetMaximumWithdrawAmountRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getMaximumWithdrawAmount(requestParameters.accountId, requestParameters.paymentMethodId, requestParameters.recurringId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get common payment info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPaymentInfo(options?: any) {
        return PaymentsApiFp(this.configuration).getPaymentInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get awailable deposit methods
     * @param {PaymentsApiGetPaymentMethodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getPaymentMethods(requestParameters: PaymentsApiGetPaymentMethodsRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getPaymentMethods(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate fee for withdraw
     * @param {PaymentsApiGetWithdrawFeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getWithdrawFee(requestParameters: PaymentsApiGetWithdrawFeeRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getWithdrawFee(requestParameters.paymentMethodId, requestParameters.accountId, requestParameters.amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get awailable withdraw methods
     * @param {PaymentsApiGetWithdrawMethodsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getWithdrawMethods(requestParameters: PaymentsApiGetWithdrawMethodsRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).getWithdrawMethods(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public getWithdrawWidgets(options?: any) {
        return PaymentsApiFp(this.configuration).getWithdrawWidgets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make deposit on real account
     * @param {PaymentsApiMakeDepositRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public makeDeposit(requestParameters: PaymentsApiMakeDepositRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).makeDeposit(requestParameters.paymentMethodId, requestParameters.accountId, requestParameters.recurringId, requestParameters.amount, requestParameters.deviceType, requestParameters.id, requestParameters.isBonusesRejected, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make withdraw from real account
     * @param {PaymentsApiMakeWithdrawRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public makeWithdraw(requestParameters: PaymentsApiMakeWithdrawRequest = {}, options?: any) {
        return PaymentsApiFp(this.configuration).makeWithdraw(requestParameters.paymentMethodId, requestParameters.accountId, requestParameters.recurringId, requestParameters.amount, requestParameters.deviceType, requestParameters.withdrawRequestPayload, options).then((request) => request(this.axios, this.basePath));
    }
}
