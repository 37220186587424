import { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";

import { TerminalOrderSorting, TerminalPosition } from "@/services/openapi";
import { useInfiniteTerminalPositionsQuery } from "@/state/server/terminal";

const useLoadClosedPositions = ({ accountId }: { accountId: string }) => {
  const { ref, inView } = useInView();

  const { fetchNextPage, hasNextPage, data, isFetchingNextPage, isLoading } = useInfiniteTerminalPositionsQuery({
    pageSize: 40,
    tradingAccountId: accountId,
    sorting: TerminalOrderSorting.ByDateCloseDesc,
  });

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage]);

  const items = useMemo(() => {
    const items: TerminalPosition[] = [];

    data?.pages.forEach(page => {
      items.push(...page.items!);
    });

    return items;
  }, [data?.pages]);

  return { ref, hasNextPage, isLoading, items };
};

export { useLoadClosedPositions };
