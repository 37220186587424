import { ComponentPropsWithoutRef, FC, useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import { InvalidCountryDialog } from "@/features/onboarding/components/steps/profile/invalid-country-dialog/dialog";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { PlatformCountry } from "@/services/openapi";
import { Loader, NewSelect } from "@/shared/ui";
import { usePlatformCountriesQuery } from "@/state/server/platform";

import { ProfileFields } from "./profile.form";

type Props = Omit<ComponentPropsWithoutRef<typeof NewSelect>, "children"> & {
  unsupportedCountries: PlatformCountry[];
  country: string;
};

const CountrySelect: FC<Props> = ({ unsupportedCountries, country, ...props }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    clearErrors,
    trigger,
  } = useFormContext();
  const { data } = usePlatformCountriesQuery();
  const [opened, { onOpenChange, open }] = useDisclosure();

  const showUnsupportedCountry = useCallback(
    (country: string) => {
      const isInvalidCountry = unsupportedCountries.map(({ country }) => country).includes(country);

      if (isInvalidCountry) {
        return t("onboarding.address.country-error");
      }

      clearErrors(ProfileFields.COUNTRY);
      return true;
    },
    [clearErrors, t, unsupportedCountries],
  );

  const [countryField, { invalid: isCountryFieldInvalid, errorMessage }] = useControlledField({
    name: ProfileFields.COUNTRY,
    control,
    defaultValue: country,
    rules: {
      required: true,
      validate: showUnsupportedCountry,
    },
  });

  /* FIXME: убрать валидацию из useEffect */
  useEffect(() => {
    if (errors[ProfileFields.COUNTRY]) {
      open();
    }
  }, [errors[ProfileFields.COUNTRY]]);

  if (!data) return <Loader />;

  const allSortedCountries = [...(data.countries || []), ...(data.unsupportedCountries || [])].sort((a, b) => {
    return a.country!.localeCompare(b.country!);
  });

  return (
    <>
      <NewSelect
        label={t("onboarding.address.country")}
        onValueChange={(value: string) => {
          countryField.onChange(value);
          trigger(ProfileFields.COUNTRY);
        }}
        value={countryField.value}
        invalid={isCountryFieldInvalid}
        descriptor={errorMessage}
        {...props}
      >
        {allSortedCountries.map(country => (
          <NewSelect.Item key={country.code} value={country.country!}>
            <div className="my-1 flex items-center gap-4">
              <img src={country.flag!} className="size-5" />
              {country.country}
            </div>
          </NewSelect.Item>
        ))}
      </NewSelect>
      <InvalidCountryDialog opened={opened} onOpenChange={onOpenChange} />
    </>
  );
};

export { CountrySelect };
