import Big from "big.js";

const getTickSizeFromDecimalScale = (decimalScale: number): number => {
  if (decimalScale < 1) {
    return 1;
  }
  return Number("0." + "0".repeat(decimalScale - 1) + "1");
};

const getDecimalScaleFromTickSize = (tickSize: number): number => {
  if (tickSize === 1) {
    return 0;
  }
  return String(tickSize)?.split(".")[1]?.length;
};

// needed for pretty input values
const formatInputNumberValue = (value: number | string | null | undefined, decimalScale: number) => {
  if (typeof value === "number") {
    return new Big(value).toFixed(decimalScale);
  }

  if (typeof value === "string") {
    // check for invalid string
    try {
      return new Big(value).toFixed(decimalScale);
    } catch (e) {
      return "";
    }
  }
  return "";
};

// needed to extract value from field
const getInputNumberValue = (value: number | string | undefined | null): number | null => {
  if (value === null || value === "" || isNaN(+value!)) {
    return null;
  }
  return +value!;
};

export { formatInputNumberValue, getDecimalScaleFromTickSize, getTickSizeFromDecimalScale, getInputNumberValue };
