import * as Popover from "@radix-ui/react-popover";
import { type FC, useMemo } from "react";

import { PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { IconChevronDown } from "@/domains/icons";
import { AccountBadge } from "@/entities/accounts/badge";
import { BonusDialog } from "@/entities/bonuses/dialog/bonus-dialog";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { Dialog, popoverContentStyles, Text } from "@/shared/ui";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { useTerminalAccountSummary } from "../account-summary/context";
import { useTerminalAccountContext } from "../contexts/account.context";
import { useOrdersContext } from "../contexts/orders.context";
import { useLayoutContext } from "../layout/context";
import { AccountsSelectPopoverContent } from "./popover-content";

const AccountSelect: FC = () => {
  const { account, setAccount, bonus, showBonus } = useTerminalAccountContext();

  const { pnl, currency, currencyDecimalScale } = useTerminalAccountSummary();

  const { openOrders } = useOrdersContext();

  const [opened, { onOpenChange, close: closePopover }] = useDisclosure();

  const { isMobile } = useLayoutContext();

  const { data } = useAllAccountsQuery({
    isTerminalEnabledOnly: true,
    status: AvailabilityStatus.Active,
    sorting: TradingAccountSorting.Newest,
  });

  const accounts = useMemo(
    () =>
      (data || [])
        .filter(({ id }) => id !== account.id)
        .sort(a => {
          if (account.type === TradingAccountType.Demo) {
            return a.type === TradingAccountType.Demo ? -1 : 1;
          } else {
            return a.type === TradingAccountType.Real ? -1 : 1;
          }
        }),
    [data, account.id, account.type],
  );

  const hasOrders = openOrders.length > 0;

  return (
    <Dialog>
      <Popover.Root modal open={opened} onOpenChange={onOpenChange}>
        <Popover.Trigger asChild>
          <button className="inline-flex h-12 items-center gap-3 rounded-[12px] px-2 outline-none transition-colors hover:bg-control-bg-hover data-[state=open]:bg-control-bg-active">
            <AccountBadge type={account.type!} />
            <div className="flex flex-col gap-0.5">
              <div className="flex items-center gap-1">
                <Text variant="S / Medium" color="primary" className="tabular-nums">
                  <PositiveNumberFormat
                    currency={account.currency!}
                    value={account.equity}
                    decimalScale={account.digits}
                  />
                </Text>
                <IconChevronDown className="size-4 shrink-0" />
              </div>
              {hasOrders && (
                <Text variant="XS / Regular" color={getNumberTextColor(pnl) ? getNumberTextColor(pnl) : "secondary"}>
                  <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
                </Text>
              )}
            </div>
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            align={isMobile ? "center" : "end"}
            collisionPadding={15}
            className={cn(
              popoverContentStyles(),
              "max-h-[--radix-popover-content-available-height] w-[min(360px,_90vw)] overflow-y-auto rounded-[16px] bg-surface-elevation-1 shadow outline-none scrollbar-hide",
            )}
            sideOffset={8}
          >
            <AccountsSelectPopoverContent
              onClose={closePopover}
              accounts={accounts}
              currentAccount={account}
              setAccount={setAccount}
              showBonus={showBonus}
              bonus={bonus}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      {bonus && <BonusDialog bonus={bonus} />}
    </Dialog>
  );
};

AccountSelect.displayName = "TerminalAccountSelect";

export { AccountSelect };
