import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import { Field } from "@/services/openapi";
import { NewNumberInput, NewSelect, NewTextInput } from "@/shared/ui";

export const AdditionalField: FC<Field> = ({ id, title, type, validation, options }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const fieldsValidate = (validation: Field["validation"]) => (value: string) => {
    const regexpData = validation?.regexp;
    if (!regexpData) return true;

    const regexp = new RegExp(regexpData.expression!, regexpData.flags!);

    // TODO: В типах title нет, по факту приходит, когда типы починяться удалить ts-ignore
    // @ts-ignore
    return regexp.test(value) ? true : regexpData.error?.title!;
  };

  const selectOptions = useMemo(
    () => options?.map(option => ({ value: option.value!, label: option.title! })),
    [options],
  );

  const [field, { invalid, errorMessage }] = useControlledField({
    name: id!,
    control,
    rules: {
      required: t("validation.required")!,
      validate: fieldsValidate(validation),
    },
  });

  return (
    <>
      {type === "text" && (
        <NewTextInput {...field} placeholder={title ?? ""} invalid={invalid} descriptor={errorMessage} />
      )}

      {type === "number" && (
        <NewNumberInput {...field} placeholder={title ?? ""} invalid={invalid} descriptor={errorMessage} />
      )}

      {type === "select" && selectOptions && (
        <NewSelect value={field.value} placeholder={title ?? ""} invalid={invalid} descriptor={errorMessage}>
          {options?.map(({ title, value }) => {
            return (
              <NewSelect.Item key={value} value={value!}>
                {title}
              </NewSelect.Item>
            );
          })}
        </NewSelect>
      )}
    </>
  );
};
