import {
  getDepositFee,
  getDepositMethods,
  getDepositWidgets,
  getMaximumDepositAmount,
  getMaximumWithdrawAmount,
  getPaymentInfo,
  getWithdrawFee,
  getWithdrawMethods,
  getWithdrawWidgets,
} from "@/services/payment";
import { ApiRequestParamsType, ApiResponseType } from "@/services/types";
import { useBaseQuery } from "@/state/server";
import { paymentQueryKeys } from "@/state/server/payment/payment.keys";
import { QueryOptionsType } from "@/state/server/types";

export const useDepositMethodQuery = (
  filters?: ApiRequestParamsType<typeof getWithdrawMethods>,
  options?: QueryOptionsType<ApiResponseType<typeof getDepositMethods>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getDepositMethods>>({
    queryKey: paymentQueryKeys.depositMethods(filters),
    queryFn: () => getDepositMethods(filters),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useWithdrawMethodQuery = (
  filters?: ApiRequestParamsType<typeof getWithdrawMethods>,
  options?: QueryOptionsType<ApiResponseType<typeof getWithdrawMethods>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getWithdrawMethods>>({
    queryKey: paymentQueryKeys.withdrawMethods(filters),
    queryFn: () => getWithdrawMethods(filters),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useDepositFeeQuery = (
  filters: ApiRequestParamsType<typeof getDepositFee>,
  options?: QueryOptionsType<ApiResponseType<typeof getDepositFee>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getDepositFee>>({
    queryKey: paymentQueryKeys.depositFee(filters),
    queryFn: () => getDepositFee(filters),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useWithdrawFeeQuery = (
  filters: ApiRequestParamsType<typeof getWithdrawFee>,
  options?: QueryOptionsType<ApiResponseType<typeof getWithdrawFee>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getWithdrawFee>>({
    queryKey: paymentQueryKeys.withdrawFee(filters),
    queryFn: () => getWithdrawFee(filters),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useMaximumWithdrawAmountQuery = (
  filters: ApiRequestParamsType<typeof getMaximumWithdrawAmount>,
  options?: QueryOptionsType<ApiResponseType<typeof getMaximumWithdrawAmount>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getMaximumWithdrawAmount>>({
    queryKey: paymentQueryKeys.maximumWithdrawAmount(filters),
    queryFn: () => getMaximumWithdrawAmount(filters),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useDepositWidgetsQuery = (options?: QueryOptionsType<ApiResponseType<typeof getDepositWidgets>>) => {
  return useBaseQuery<ApiResponseType<typeof getDepositWidgets>>({
    queryKey: paymentQueryKeys.depositWidgets(),
    queryFn: getDepositWidgets,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useWithdrawWidgetsQuery = (options?: QueryOptionsType<ApiResponseType<typeof getWithdrawWidgets>>) => {
  return useBaseQuery<ApiResponseType<typeof getWithdrawWidgets>>({
    queryKey: paymentQueryKeys.withdrawWidgets(),
    queryFn: getWithdrawWidgets,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const usePaymentInfo = (options?: QueryOptionsType<ApiResponseType<typeof getPaymentInfo>>) => {
  return useBaseQuery<ApiResponseType<typeof getPaymentInfo>>({
    queryKey: paymentQueryKeys.info(),
    queryFn: getPaymentInfo,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};

export const useMaximumDepositAmountQuery = (
  filters: ApiRequestParamsType<typeof getMaximumDepositAmount>,
  options?: QueryOptionsType<ApiResponseType<typeof getMaximumDepositAmount>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getMaximumDepositAmount>>({
    queryKey: paymentQueryKeys.maximumDepositAmount(filters),
    queryFn: () => getMaximumDepositAmount(filters),
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  });
};
