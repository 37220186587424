import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { NewDialogContent } from "@/components/new-dialog";
import img from "@/domains/ui-kit/IconsGlass/shield.svg";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import { MaximumLimitDescription } from "@/services/openapi";
import { Dialog, NewButton } from "@/shared/ui";
import { useProfileData } from "@/state/server/profile/profile.hooks";

export const fromDepositState = "fromDeposit";

type Props = {
  open: boolean;
  submitDeposit: () => void;
  onClose: (open: boolean) => void;
  depositLimit: number | undefined;
  currency: string;
  decimalScale: number;
  withdrawLimit: MaximumLimitDescription;
  amount: number;
};

const VerificationContainer: FC<Props> = ({
  currency,
  decimalScale,
  depositLimit,
  onClose,
  open,
  submitDeposit,
  withdrawLimit,
  amount,
}) => {
  const { t } = useTranslation();
  const profileData = useProfileData();
  const isSurveyMandatory = profileData.featuresInfo!.mandatorySurvey;
  const isSurveyCompleted = profileData.options!.isSurveyCompleted!;
  const verificationRoute = getVerificationRoute(isSurveyCompleted);
  const isAmountMoreThanLimit = depositLimit && amount > depositLimit;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <NewDialogContent
        image={{ src: img }}
        title={
          !isSurveyMandatory
            ? t("deposit.verification.dialog.mandatory.title")!
            : t("deposit.verification.dialog.title")!
        }
        description={
          !isSurveyMandatory ? (
            t("deposit.verification.dialog.mandatory.description")
          ) : (
            <Trans
              i18nKey="deposit.verification.dialog.description"
              values={{
                currency,
              }}
              components={{
                depositAmount: <NumberFormat value={depositLimit} decimalScale={decimalScale} />,
                withdrawAmount: (
                  <NumberFormat
                    value={withdrawLimit.extendedInfo!.withdrawCurrentLimitNoKyc}
                    decimalScale={decimalScale}
                  />
                ),
              }}
            />
          )
        }
      >
        <div className="flex flex-col gap-2">
          {isSurveyMandatory ? (
            <>
              {!isAmountMoreThanLimit && (
                <Dialog.Close asChild>
                  <NewButton onClick={submitDeposit} fullWidth>
                    {t("deposit.verification.dialog.button-payment")}
                  </NewButton>
                </Dialog.Close>
              )}
              <NewButton variant="secondary" fullWidth asChild>
                <Link to={verificationRoute} state={fromDepositState}>
                  {t("deposit.verification.dialog.button-verify")}
                </Link>
              </NewButton>
            </>
          ) : (
            <NewButton fullWidth asChild>
              <Link to={verificationRoute} state={fromDepositState}>
                {t("deposit.verification.dialog.button-verify")}
              </Link>
            </NewButton>
          )}
        </div>
      </NewDialogContent>
    </Dialog>
  );
};

export { VerificationContainer };
