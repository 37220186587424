import { type FC } from "react";

import { BonusUserPlatformItemsContainer, TradingAccount } from "@/services/openapi";

import { DepositOptionsForm } from "./components/form/deposit-options.form";

type Props = {
  currentAccount: TradingAccount;
  bonusesData: BonusUserPlatformItemsContainer;
};

const DepositOptionsContainer: FC<Props> = ({ currentAccount, bonusesData }) => {
  return <DepositOptionsForm bonuses={bonusesData.items!} currentAccount={currentAccount} />;
};

export { DepositOptionsContainer };
