import { ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";
import { NumericFormat, type NumericFormatProps } from "react-number-format";

type Props = Omit<NumericFormatProps, "inputRef"> & { currency?: string };

const _NumericInput = forwardRef<ElementRef<"input">, Props>(
  (
    {
      inputMode = "decimal",
      allowLeadingZeros = false,
      allowNegative = false,
      thousandSeparator = ",",
      allowedDecimalSeparators = [",", "."],
      currency,
      ...props
    },
    ref,
  ) => (
    <NumericFormat
      allowedDecimalSeparators={allowedDecimalSeparators}
      inputMode={inputMode}
      allowLeadingZeros={allowLeadingZeros}
      allowNegative={allowNegative}
      thousandSeparator={thousandSeparator}
      getInputRef={ref}
      suffix={currency ? ` ${currency}` : undefined}
      {...props}
    />
  ),
);

type _ExternalNumericInputProps = Omit<
  ComponentPropsWithoutRef<typeof _NumericInput>,
  "value" | "onValueChange" | "onChange" | "size"
>;

// TODO: remove replace _NewNumericInput name with _NumericInput
// TODO: remove replace _NewExternalNumericInputProps name with _ExternalNumericInputProps

export { _NumericInput as _NewNumericInput };
export type { _ExternalNumericInputProps as _NewExternalNumericInputProps };
