import { FC } from "react";

import logoDark from "@/assets/images/LogoDark.svg";
import { Link } from "@/components/link";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";

type Props = {
  asLink: boolean;
};

const Logo: FC<Props> = ({ asLink }) => {
  const { isMobile } = useScreenSize();
  const styles = !isMobile ? "w-[102px] h-[28px]" : "w-[75px] h-[20px]";

  return (
    <>
      {!asLink ? (
        <img alt="doto" src={logoDark} className={styles} />
      ) : (
        <Link to={cabinetRoutes.dashboard} css={{ display: "flex" }}>
          <img alt="doto" src={logoDark} className={styles} />
        </Link>
      )}
    </>
  );
};

export { Logo };
