import { produce } from "immer";
import { createContext, memo, type ReactNode, useCallback, useContext, useEffect, useMemo } from "react";
import { useQueryClient } from "react-query";

import { useDisclosure } from "@/hooks/disclosure.hook";
import { ProfileHeader } from "@/services/openapi";
import { profileQueryKeys } from "@/state/server/profile";
import { useProfileData } from "@/state/server/profile/profile.hooks";
import { useDisableTargetPriceDialogMutation } from "@/state/server/profile/profile.mutations";

import { type SignalLineInfo } from "../chart/signal/chart-line";
import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useLayoutContext, webviewChannel } from "../layout/context";
import { getHasSignalCondition, getIsUpSignal } from "./helpers";

// for webview
const closeSignalLinesEvent = "hide-resistance-lines";
const showSignalLinesEvent = "show-resistance-lines";

window.closeSignalLinesEvent = new CustomEvent(closeSignalLinesEvent);
window.showSignalLinesEvent = new CustomEvent(showSignalLinesEvent);

type ContextProps = {
  signalFormOpened: boolean;
  hasSignal: boolean;
  openSignalForm: () => void;
  closeSignalForm: () => void;
  isSignalLinesShown: boolean;
  showSignalLines: () => void;
  closeSignalLines: () => void;
  isSignalChartDialogShown: boolean;
  showSignalChartDialog: () => void;
  closeSignalChartDialog: () => void;
  chartLines: SignalLineInfo[];
  isTerminalTargetPricesEnabled: boolean;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider = memo(({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();

  const { isMobileChartPage } = useLayoutContext();

  const { options } = useProfileData();

  const isTerminalTargetPricesEnabled = options!.isTerminalTargetPricesEnabled!;
  const isTerminalTargetPricesDialogEnabled = options!.isTerminalTargetPricesDialogEnabled!;

  const { mutate: disableDialog } = useDisableTargetPriceDialogMutation();

  const { symbolInfo, isTradingAvailable } = useCurrentSymbolContext();

  const { signal, priceAsk, priceBid } = symbolInfo;

  const [_signalFormOpened, { open: openSignalForm, close: closeSignalForm }] = useDisclosure(false);
  const [_isSignalLinesShown, { open: _showSignalLines, close: _closeSignalLines }] = useDisclosure(false);
  const [_isSignalChartDialogShown, { open: showSignalChartDialog, close: _closeSignalChartDialog }] =
    useDisclosure(false);

  const showSignalLines: ContextProps["showSignalLines"] = useCallback(() => {
    _showSignalLines();
    if (webviewChannel) {
      webviewChannel.postMessage("showResistanceLines");
    }
  }, [_showSignalLines]);

  const closeSignalLines: ContextProps["closeSignalLines"] = useCallback(() => {
    _closeSignalLines();
    if (webviewChannel) {
      webviewChannel.postMessage("hideResistanceLines");
    }
  }, [_closeSignalLines]);

  const closeSignalChartDialog = useCallback(() => {
    if (isTerminalTargetPricesDialogEnabled) {
      disableDialog(undefined);
      queryClient.setQueryData<ProfileHeader>(profileQueryKeys.header(), oldData => {
        return produce(oldData, draft => {
          draft!.options!.isTerminalTargetPricesDialogEnabled = false;
        })!;
      });
    }
    _closeSignalChartDialog();
  }, [_closeSignalChartDialog, disableDialog, isTerminalTargetPricesDialogEnabled, queryClient]);

  useEffect(() => {
    const handleShow = () => {
      showSignalLines();
    };
    const handleClose = () => {
      closeSignalLines();
    };

    // document.dispatchEvent(event);
    document.addEventListener(closeSignalLinesEvent, handleClose);
    document.addEventListener(showSignalLinesEvent, handleShow);

    return () => {
      document.removeEventListener(closeSignalLinesEvent, handleClose);
      document.removeEventListener(showSignalLinesEvent, handleShow);
    };
  }, []);

  useEffect(() => {
    closeSignalForm();
    closeSignalLines();
  }, [symbolInfo.symbol]);

  const hasSignal = useMemo(
    () => getHasSignalCondition({ isTradingAvailable, signal, ask: priceAsk, bid: priceBid }),
    [isTradingAvailable, signal, priceAsk, priceBid],
  );

  const isSignalChartDialogShown = useMemo(
    () => hasSignal && (_isSignalChartDialogShown || isTerminalTargetPricesDialogEnabled),
    [hasSignal, _isSignalChartDialogShown, isTerminalTargetPricesDialogEnabled],
  );

  const signalFormOpened = _signalFormOpened && hasSignal;
  const isSignalLinesShown = _isSignalLinesShown && hasSignal;

  const chartLines: ContextProps["chartLines"] = useMemo(() => {
    if (!hasSignal) {
      return [];
    }

    if (!signalFormOpened && !isMobileChartPage) {
      return [];
    }

    const priceDecimalScale = symbolInfo.digits!;
    const isUpSignal = getIsUpSignal(signal!.type!);

    return [
      {
        priceDecimalScale,
        price: signal!.priceSupport1!,
        color: "negative",
      },
      {
        priceDecimalScale,
        price: signal!.priceResistance1!,
        color: "positive",
      },
      isUpSignal
        ? { priceDecimalScale, price: signal!.priceSupport2!, color: "negative" }
        : { priceDecimalScale, price: signal!.priceResistance2!, color: "positive" },
    ];
  }, [symbolInfo.digits, signal, signalFormOpened, hasSignal, isMobileChartPage]);

  const value: ContextProps = useMemo(
    () => ({
      signalFormOpened,
      openSignalForm,
      closeSignalForm,
      hasSignal,
      isSignalLinesShown,
      showSignalLines,
      closeSignalLines,
      isSignalChartDialogShown,
      showSignalChartDialog,
      closeSignalChartDialog,
      chartLines,
      isTerminalTargetPricesEnabled,
    }),
    [
      signalFormOpened,
      openSignalForm,
      closeSignalForm,
      hasSignal,
      isSignalLinesShown,
      showSignalLines,
      closeSignalLines,
      isSignalChartDialogShown,
      showSignalChartDialog,
      closeSignalChartDialog,
      chartLines,
      isTerminalTargetPricesEnabled,
    ],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
});

Provider.displayName = "TerminalSignalContextProvider";

const useSignalContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useSignalContext must be used within a TerminalSignalContextProvider");
  }

  return context;
};

export { Provider as TerminalSignalContextProvider, useSignalContext };
