import { FC, Fragment } from "react";
import { useFormContext } from "react-hook-form";

import { useControlledField } from "@/app/form";
import { useTranslation } from "@/hooks/translator.hook";
import { SurveyField as SurveyFieldType } from "@/services/openapi";
import { FieldView } from "@/services/openapi/models/field-view";
import { NewSelect, RadioGroup, Text } from "@/shared/ui";

import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";
import { SurveyField } from "../survey-field";
import { usePollOptions } from "../use.poll.options.hook";

type Props = {
  field: SurveyFieldType;
};

export const SurveyPollSingleField: FC<Props> = ({
  field: { isRequired, title, id, pollFields, type, view, pollValues, errorMessage: errorMessageProps, placeholder },
}) => {
  const { defaultValue, options } = usePollOptions({ pollFields, pollValues });
  const { t } = useTranslation();

  const { control, watch } = useFormContext();
  const [field, { invalid, errorMessage }] = useControlledField({
    name: generateSurveyFieldId(id!, type!),
    defaultValue,
    control,
    rules: {
      required: errorMessageProps ?? t("form-errors.required-error")!,
    },
  });

  return (
    <>
      <Text variant="M / Regular">{generateSurveyFieldTitle(title!)}</Text>

      {view === FieldView.Select && (
        <>
          <NewSelect
            placeholder={placeholder ?? t("fields.select.placeholder")}
            value={field.value}
            onValueChange={field.onChange}
            invalid={invalid}
            descriptor={errorMessage}
            name={field.name}
          >
            {options.map(({ label, value }) => (
              <NewSelect.Item key={value} value={value}>
                {label}
              </NewSelect.Item>
            ))}
          </NewSelect>
        </>
      )}
      {view === FieldView.Radio && (
        <>
          <RadioGroup value={field.value} onValueChange={field.onChange} name={field.name}>
            {options.map(({ value, label }) => (
              <RadioGroup.Item key={value} label={label} value={value} />
            ))}
          </RadioGroup>
          {errorMessage && (
            <Text variant="S Compact / Regular" color="negative">
              {errorMessage}
            </Text>
          )}
        </>
      )}

      {pollFields!.map(({ id: pollId, title: pollTitle, fields, errorMessage, placeholder }) => {
        if (watch(generateSurveyFieldId(id!, type!)) === pollId) {
          return (
            <Fragment key={pollTitle}>
              {fields!.map(field => (
                <SurveyField
                  field={{
                    ...field,
                    isRequired: field.isRequired ? field.isRequired : isRequired,
                    errorMessage,
                    placeholder,
                  }}
                  key={field.id}
                />
              ))}
            </Fragment>
          );
        }
        return null;
      })}
    </>
  );
};
