import { cva } from "class-variance-authority";
import { type FC, memo, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

import { Logo } from "@/app/components";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { isCurrentPageTerminal } from "@/routes/terminal.routes";
import { NewButton } from "@/shared/ui";
import { useIsReadOnly } from "@/state/server/profile/profile.hooks";

const linkStyles = cva("typography-S-Medium text-contrast-secondary transition-colors hover:text-contrast-primary", {
  variants: {
    active: {
      true: "!text-contrast-primary",
    },
  },
});

type Props = {
  children?: ReactNode;
  showWithdrawalButton?: boolean;
};

const Header: FC<Props> = ({ children, showWithdrawalButton }) => {
  const { t } = useTranslation();

  const location = useLocation();

  const isReadOnly = useIsReadOnly();
  const { isLoading, open } = useTerminalAutoRoute();

  const isTerminalActive = useMemo(() => isCurrentPageTerminal(location.pathname), [location.pathname]);

  return (
    <div className="flex h-16 min-h-16 items-center justify-between bg-surface-canvas pe-4 ps-6">
      <div className="flex items-center gap-10">
        <div className="flex items-center gap-4">
          <Logo />
          {/* TODO: theme switch */}
        </div>
        <div className="flex items-center gap-8">
          <button
            type="button"
            data-test="navlink-terminal"
            onClick={open}
            disabled={isLoading}
            className={linkStyles({
              active: isTerminalActive,
            })}
          >
            {t("cabinet.navigation.terminal")}
          </button>
          <NavLink
            className={({ isActive }) => linkStyles({ active: isActive })}
            to={cabinetRoutes.dashboard}
            data-test="navlink-accounts"
          >
            {t("cabinet.navigation.accounts")}
          </NavLink>
          <NavLink
            className={({ isActive }) => linkStyles({ active: isActive })}
            to={cabinetRoutes.profileSettings}
            data-test="navlink-profile-settings"
          >
            {t("cabinet.navigation.profile-settings")}
          </NavLink>
        </div>
      </div>
      <div className="flex items-center gap-4">
        {children}
        <div className="flex items-center gap-3">
          {!isReadOnly && showWithdrawalButton && (
            <NewButton size="sm" variant="secondary">
              <Link to={cabinetRoutes.withdrawal} data-test="navlink-withdrawal">
                {t("cabinet.navigation.withdrawal")}
              </Link>
            </NewButton>
          )}
          {!isReadOnly && (
            <NewButton size="sm" asChild>
              <Link to={cabinetRoutes.deposit} state={{ from: location.pathname }} data-test="navlink-deposit">
                {t("cabinet.navigation.deposit")}
              </Link>
            </NewButton>
          )}
        </div>
      </div>
    </div>
  );
};

const Component = memo(Header);

export { Component as Header };
