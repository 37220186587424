import dayjs from "dayjs";
import { FC, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import { DateField } from "@/components/form/fields";
import { usePlatformData } from "@/state/server/platform/platform.hooks";
import { isTooOld, isYoungerThan } from "@/utils/dates";

import { ProfileFields } from "./profile.form";

const DateFieldOnboarding: FC = () => {
  const { t } = useTranslation();
  const { control, setError, clearErrors } = useFormContext();
  const { validation } = usePlatformData();

  const ageMin = validation!.user!.ageMin!;

  const validateDateOfBirthMain = useCallback(
    (date: string) => {
      const now = dayjs();
      const isValidDate = !!date && !isNaN(Date.parse(date)) && isTooOld(date) && dayjs(date).isBefore(now);
      const isYoung = isYoungerThan(date, ageMin);

      if (!isValidDate) {
        return t("onboarding.profile.birth-date-error-invalid");
      }

      if (isYoung) {
        return t("form-errors.min-age-error");
      }

      clearErrors();
      return true;
    },
    [ageMin, clearErrors, t],
  );

  const validateDateOfBirth = useCallback(
    (date: string) => {
      if (date.split("-")[0].length === 4) {
        return validateDateOfBirthMain(date);
      }
    },
    [validateDateOfBirthMain],
  );

  const onBlurValidationDateOfBirth = useCallback(
    (date: string | null | undefined) => {
      if (date) {
        const validateMessage = validateDateOfBirthMain(date);

        if (typeof validateMessage === "string") {
          setError(ProfileFields.DATE_OF_BIRTH, { type: "string", message: validateMessage }, { shouldFocus: true });
        }
      }
    },
    [setError, validateDateOfBirthMain],
  );

  const [dateField] = useControlledField({
    name: ProfileFields.DATE_OF_BIRTH,
    control,
    rules: {
      required: t("onboarding.profile.birth-date-error")!,
      validate: validateDateOfBirth,
    },
  });

  return <DateField {...dateField} showIcon={false} onBlurValidation={onBlurValidationDateOfBirth} />;
};

export { DateFieldOnboarding };
