import parse from "html-react-parser";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InvestingLogo from "@/assets/images/investing-logo.svg";
import { Box } from "@/components/box";
import { EmailField, PasswordField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Link } from "@/components/link";
import { FacebookButton, GoogleButton } from "@/components/social-button/social-button";
import { Text } from "@/components/text";
import { withCaptcha } from "@/decorators";
import { useHookForm } from "@/hooks/form.hook";
import { usePasswordRules } from "@/hooks/password-rules.hook";
import { authRoutes } from "@/routes/auth.routes";
import { PlatformInfo } from "@/services/openapi";
import { Text as NewText } from "@/shared/ui";
import { usePlatformTranslationsQuery } from "@/state/server/platform";

import { AuthBox, AuthSeparator, SocialButtonsBox } from "../styles";

enum SignUpFields {
  EMAIL = "email",
  PASSWORD = "password",
}

export interface ISignUpFormValues {
  [SignUpFields.EMAIL]: string;
  [SignUpFields.PASSWORD]: string;
}

interface Props {
  onSubmit: (values: ISignUpFormValues) => void;
  disabledSubmit: boolean;
  platformInfo: PlatformInfo;
  isByInvestingCampaign: boolean;
}

const _SingUpForm: FC<Props> = ({ onSubmit, disabledSubmit, platformInfo, isByInvestingCampaign }) => {
  const { i18n, t } = useTranslation();
  const { data: translationsData } = usePlatformTranslationsQuery({ locale: i18n.resolvedLanguage });
  const form = useHookForm<ISignUpFormValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [password, setPassword] = useState<string | undefined>(undefined);
  const { watch, formState } = form;

  const { border, passwordText, maxLength } = usePasswordRules(password);

  useEffect(() => {
    if (formState.isSubmitted) {
      setPassword(watch(SignUpFields.PASSWORD));
    }
  }, [formState.isSubmitting]);

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      {platformInfo.signIn!.facebook!.isEnabled && platformInfo.signIn!.google!.isEnabled && (
        <>
          <SocialButtonsBox css={{ mt: "16px" }}>
            <GoogleButton css={{ "@bp3": { width: "100%" } }} />
            <FacebookButton css={{ "@bp3": { width: "100%" } }} />
          </SocialButtonsBox>
          <AuthSeparator css={{ mt: "22px", mb: "22px" }}>
            <Box css={{ background: "#efefef", zIndex: 1, p: 8 }}>{t("auth.use-mail")}</Box>
          </AuthSeparator>
        </>
      )}
      <AuthBox>
        <EmailField
          name={SignUpFields.EMAIL}
          bottomOffset="1"
          placeholder={t("fields.email.email")!}
          data-test="sign-up-email-input"
        />
        <PasswordField
          name={SignUpFields.PASSWORD}
          bottomOffset="4"
          placeholder={t("fields.password.create")!}
          border={border}
          passwordText={passwordText}
          maxLength={maxLength}
          data-test="sign-up-password-input"
        />
      </AuthBox>
      <AuthBox css={{ display: "flex", justifyContent: "center", mb: "10px", width: "100%" }}>
        <SubmitButton wide checkValid={false} data-test="sign-up-btn">
          {t("auth.sign-up.create-account")}
        </SubmitButton>
      </AuthBox>
      <AuthBox css={{ textAlign: "center", mb: "33px" }}>
        <Text family="roboto">
          {t("auth.sign-up.already-account")}{" "}
          <Link to={authRoutes.signIn} css={{ fontFamily: "$gilroy", fontWeight: "$bolder" }}>
            {t("auth.sign-in.title")}
          </Link>
        </Text>
      </AuthBox>
      <AuthBox css={{ mb: "24px" }} data-test="sign-up-text">
        <Text color="dimmed" family="roboto" lineHeight="3">
          {translationsData && parse(translationsData["legal::sign_up"])}
        </Text>

        {isByInvestingCampaign && (
          <div className="mt-8 flex items-center justify-center gap-2">
            <NewText variant="M Compact / Medium" color="tertiary">
              {t("auth.sign-up.trusted")}
            </NewText>
            <img src={InvestingLogo} />
          </div>
        )}
      </AuthBox>
    </HookForm>
  );
};

export const SingUpForm = withCaptcha(_SingUpForm);
