import { FC } from "react";
import { useTranslation } from "react-i18next";

import { PaymentWrapper } from "@/features/payment/ui/wrapper";
import { CheckStep } from "@/features/payment/withdrawal/components/form/steps/check/step";
import { Field, PaymentMethod, TradingAccount } from "@/services/openapi";
import { useWithdrawFeeQuery } from "@/state/server/payment";

type Props = {
  submitIsloading: boolean;
  currentAccount: TradingAccount;
  currentPaymentMethod: PaymentMethod;
  additionalFieldsData: Field[] | null;
  currentAmount: string;
  onSubmit: () => void;
  back: () => void;
};

const CheckStepContainer: FC<Props> = ({
  submitIsloading,
  currentAccount,
  currentPaymentMethod,
  additionalFieldsData,
  currentAmount,
  back,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { data: fee } = useWithdrawFeeQuery({
    paymentMethodId: currentPaymentMethod.id,
    accountId: currentAccount.id,
    amount: currentAmount,
  });

  return (
    <PaymentWrapper title={t("payments.check.amount.withdrawal")} onBack={back}>
      {fee ? (
        <CheckStep
          fee={fee.value!}
          currentAmount={currentAmount}
          currentAccount={currentAccount}
          currentPaymentMethod={currentPaymentMethod}
          additionalFieldsData={additionalFieldsData}
          back={back}
          onSubmit={onSubmit}
          submitIsloading={submitIsloading}
        />
      ) : (
        <PaymentWrapper.Loader />
      )}
    </PaymentWrapper>
  );
};

export { CheckStepContainer };
