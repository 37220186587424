import { VariantProps } from "@stitches/react";
import {
  ComponentPropsWithoutRef,
  Dispatch,
  ElementRef,
  forwardRef,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { Description, Label } from "@/components/form/inputs/styles";
import type { ComboBoxProps, InputPhoneProps } from "@/domains/ui-kit";
import { ComboBox, InputPhone } from "@/domains/ui-kit";
import { CountriesInfo, PlatformCountry } from "@/services/openapi";
import { Text } from "@/shared/ui";
import { CSS } from "@/styles";

import * as Styled from "./text-phone.styled";

export type TextPhoneProps = ComponentPropsWithoutRef<"input"> &
  VariantProps<typeof Styled.Wrapper> & {
    css?: CSS;
    name: string;
    description?: string;
    errorMessage?: string;
    label?: ReactNode;
    showError?: boolean;
    comboBoxProps?: Omit<ComboBoxProps, "value" | "setValue" | "optionList">;
    inputProps?: Omit<InputPhoneProps, "format">;
    countries?: CountriesInfo;
    country?: PlatformCountry;
    setCountry?: Dispatch<SetStateAction<PlatformCountry>> | ((value: PlatformCountry) => void);
  };

const getCorrectNullData = <T,>(value: T) => (value === null ? undefined : value);

const _TextPhone = forwardRef<ElementRef<"input">, TextPhoneProps>(
  (
    {
      bottomOffset,
      css,
      name,
      description,
      errorMessage,
      inputProps,
      comboBoxProps,
      label,
      showError,
      countries,
      country,
      setCountry,
    },
    ref,
  ) => {
    const correctCountries = useMemo(
      () => [
        ...(getCorrectNullData(countries?.countries) || []),
        ...(getCorrectNullData(countries?.unsupportedCountries) || []),
      ],
      [countries],
    );

    const optionList = useMemo(
      () =>
        correctCountries.map(correctCountry => ({
          value: getCorrectNullData(correctCountry.numberCode) ?? "",
          label: getCorrectNullData(correctCountry.country) ?? "",
          icon: getCorrectNullData(correctCountry.flag),
        })),
      [correctCountries],
    );

    const updateCountryData = useCallback(
      (value?: string | null) => {
        const data = correctCountries.find(
          option => option.numberCode === value || option.country?.toLowerCase() === value || option.code === value,
        );

        data && setCountry && setCountry(data);
      },
      [correctCountries],
    );

    useEffect(() => {
      updateCountryData(countries?.current);
    }, [correctCountries]);

    const correctErrorMessage = showError ? errorMessage : undefined;
    const format = country?.mask ?? "";

    // TODO: вынести весь InputWrapper в ui-kit
    return (
      <Styled.Wrapper css={css} bottomOffset={bottomOffset}>
        {label && <Label label={label} name={name} />}
        {description && <Description description={description} />}

        <Styled.InputWrapper>
          <ComboBox
            value={country?.country || ""}
            setValue={updateCountryData}
            optionList={optionList.sort((a, b) => {
              return a.label.localeCompare(b.label);
            })}
            {...comboBoxProps}
          />
          <InputPhone format={format} inputSize="s" {...inputProps} />
        </Styled.InputWrapper>

        {correctErrorMessage && (
          <Text color="negative" variant="S Compact / Regular" className="mt-2">
            {correctErrorMessage}
          </Text>
        )}
      </Styled.Wrapper>
    );
  },
);
_TextPhone.displayName = "TextPhone";

export const TextPhone = memo(_TextPhone);
