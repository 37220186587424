import { type ReactNode, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { terminalRoutes } from "@/routes/terminal.routes";

import { type ToastProps } from ".";

type ToasterToast = ToastProps & {
  id: string;
  onClose?: () => void;
  autoClose?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  text?: ReactNode;
  action?: ReactNode;
};

type Action =
  | {
      type: "ADD_TOAST";
      toast: ToasterToast;
    }
  | {
      type: "CLEAR_TOASTS";
    }
  | {
      type: "REMOVE_TOAST";
      toastId?: ToasterToast["id"];
    };

interface State {
  toasts: ToasterToast[];
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        toasts: [...state.toasts, action.toast],
      };
    case "REMOVE_TOAST": {
      return {
        toasts: state.toasts.filter(t => t.id !== action.toastId),
      };
    }
    case "CLEAR_TOASTS":
      return {
        toasts: [],
      };
  }
};

const listeners: Array<(state: State) => void> = [];

let memoryState: State = { toasts: [] };

function dispatch(action: Action) {
  memoryState = reducer(memoryState, action);
  listeners.forEach(listener => {
    listener(memoryState);
  });
}

const toast = (props: Omit<ToasterToast, "id">) => {
  // TODO: FIXME: Remove the check
  if (window.location.pathname === terminalRoutes.terminalMobileChart) {
    return;
  }
  dispatch({
    type: "ADD_TOAST",
    toast: {
      ...props,
      id: uuidv4(),
    },
  });
};

function useToast() {
  const [state, setState] = useState<State>(memoryState);

  useEffect(() => {
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  return {
    toasts: state.toasts.slice(0, 1),
    toast,
    remove: (toastId: string) => dispatch({ type: "REMOVE_TOAST", toastId }),
  };
}

export { useToast, toast };
