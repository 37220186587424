import { FC, ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { DetailsTable as Table } from "@/components/details-table";
import { IconCheckCircle, IconCrossCircle } from "@/domains/icons";
import { Text } from "@/shared/ui";

const IconBlock: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="flex items-start gap-2 md:items-center">{children}</div>;
};

const RulesBlock: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Table.Card className="mb-6">
        <Text variant="S Compact / Regular" color="primary" className="mb-4">
          {t("withdrawal.how-to-dialog.rules.text-1")}
        </Text>

        <div className="grid gap-3">
          <IconBlock>
            <IconCheckCircle className="text-positive-text" />
            <Text variant="S Compact / Regular" color="secondary">
              {t("withdrawal.how-to-dialog.rules.correct")}
            </Text>
          </IconBlock>
          <IconBlock>
            <IconCrossCircle className="text-negative-text" />
            <Text variant="S Compact / Regular" color="secondary">
              {t("withdrawal.how-to-dialog.rules.incorrect")}
            </Text>
          </IconBlock>
        </div>
        <Text variant="S Compact / Regular" color="primary" className="mt-4">
          {t("withdrawal.how-to-dialog.rules.text-2")}
        </Text>
      </Table.Card>

      <Table.Card className="grid gap-4 text-[14px] leading-[20px]">
        <Text variant="S Compact / Regular" color="primary">
          <Trans
            i18nKey={"withdrawal.how-to-dialog.examples.text-1"}
            components={{
              bold: <span className="font-bold" />,
            }}
          />
        </Text>
        <Text variant="S Compact / Regular" color="primary">
          <Trans
            i18nKey={"withdrawal.how-to-dialog.examples.text-2"}
            components={{
              bold: <span className="font-bold" />,
            }}
          />
        </Text>
      </Table.Card>
    </>
  );
};

export { RulesBlock };
