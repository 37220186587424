import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { FieldType, SurveyField as SurveyFieldType } from "@/services/openapi";

import { SurveyBooleanField } from "./fields/survey-boolean-field";
import { SurveyDeclarationField } from "./fields/survey-declaration-field";
import { SurveyPollSingleField } from "./fields/survey-poll-single-field";
import { SurveyStringField } from "./fields/survey-string-field";
import { generateSurveyFieldId } from "./survey.helpers";

const renderField = (field: SurveyFieldType) => {
  switch (field.type) {
    case FieldType.String:
      return <SurveyStringField field={field} />;
    case FieldType.Boolean:
      return <SurveyBooleanField field={field} />;
    case FieldType.PollSingle:
      return <SurveyPollSingleField field={field} />;
    case FieldType.Declaration:
      return <SurveyDeclarationField field={field} />;
    default:
      return null;
  }
};

type Props = {
  field: SurveyFieldType;
};

export const SurveyField: FC<Props> = ({ field }) => {
  const { watch } = useFormContext();

  return (
    <>
      {renderField(field)}
      {!!watch(generateSurveyFieldId(field.id!, field.type!)) &&
        field.fields!.map(f => <SurveyField field={{ ...f }} key={f.id} />)}
    </>
  );
};
