import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AccountSelect } from "@/entities/accounts/select";
import { PaymentWrapper } from "@/features/payment/ui/wrapper/index";
import { getDepositRoute } from "@/routes/cabinet.routes";
import { TradingAccount } from "@/services/openapi";
import { NewButton } from "@/shared/ui";

type Props = {
  accounts: TradingAccount[];
  lastAccountId: string;
};

const DepositForm: FC<Props> = ({ accounts, lastAccountId }) => {
  const { t } = useTranslation();

  const [accountId, setAccountId] = useState(lastAccountId);

  return (
    <PaymentWrapper title={t("payments.account")}>
      <AccountSelect accounts={accounts} accountId={accountId} setAccountId={setAccountId} />
      <PaymentWrapper.Footer>
        <NewButton fullWidth asChild>
          <Link to={getDepositRoute(accountId)}>{t("button.next")}</Link>
        </NewButton>
      </PaymentWrapper.Footer>
    </PaymentWrapper>
  );
};

export { DepositForm };
