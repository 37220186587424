import { type ElementRef, type FC, forwardRef, memo } from "react";

import { NumberFormat } from "@/app/components";
import { AccountBadge } from "@/entities/accounts/badge";
import { TradingAccount } from "@/services/openapi";
import { NewSelect, Text } from "@/shared/ui";

const Item = forwardRef<ElementRef<"div">, { account: TradingAccount }>(({ account, ...props }, ref) => {
  const { type, login, balance, currency } = account;
  return (
    <div className="flex w-full items-center justify-between" ref={ref} {...props}>
      <div className="flex items-center gap-3">
        <AccountBadge type={type!} />
        <Text variant="S / Regular" color="secondary">
          #{login}
        </Text>
      </div>
      <Text variant="S / Regular" color="primary">
        <NumberFormat value={balance} currency={currency!} />
      </Text>
    </div>
  );
});

type Props = {
  accounts: TradingAccount[];
  accountId: string;
  setAccountId: SetState<string>;
};

const AccountSelect: FC<Props> = ({ accounts, accountId, setAccountId }) => (
  <NewSelect value={accountId} onValueChange={setAccountId}>
    {accounts.map(account => {
      return (
        <NewSelect.Item key={account.id} value={account.id!} asChild>
          <Item account={account} />
        </NewSelect.Item>
      );
    })}
  </NewSelect>
);

const Component = memo(AccountSelect);

export { Component as AccountSelect };
