import dayjs from "dayjs";
import { FC, useCallback, useState } from "react";
import { ScrollRestoration } from "react-router-dom";

import { logError } from "@/app/libs/sentry";
import { useClientId } from "@/hooks/client-id.hook";
import { PaymentMethods, PaymentMethodsWithdrawOptions, TradingAccount } from "@/services/openapi";
import { useWithdrawalMutation } from "@/state/server/payment";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { useWithdrawalInfo } from "../../withdrawal-info.hook";
import { DialogData, StatusDialog } from "../dialogs/status-dialog/status-dialog";
import { WithdrawalNoAccounts } from "../no-accounts";
import { AdditionalFieldsStep } from "./steps/additional-fields-step";
import { AmountStepContainer } from "./steps/amount/container";
import { CheckStepContainer } from "./steps/check/container";
import { PaymentMethodStepContainer } from "./steps/payment-method/container";

type Props = {
  currentAccount: TradingAccount;
  paymentMethodsData: PaymentMethods;
};

const WithdrawalOptionsForm: FC<Props> = ({ currentAccount, paymentMethodsData }) => {
  const [dialogData, setDialogData] = useState<DialogData | null>(null);
  const profile = useProfileData();
  const clientId = useClientId();

  const { mutateAsync: withdrawal, isLoading } = useWithdrawalMutation();

  const {
    currentStep,
    currentPaymentMethod,
    additionalFieldsData,
    currentAmount,
    backFromAdditionalFields,
    backFromAmount,
    backFromCheck,
    nextFromAdditionalFields,
    nextFromAmount,
    nextFromPaymentMethod,
    backFromPaymentMethod,
    setAdditionalFieldsData,
    setCurrentAmount,
    setCurrentPaymentMethod,
  } = useWithdrawalInfo();

  const onSubmit = useCallback(() => {
    return withdrawal(
      {
        accountId: currentAccount.id,
        amount: currentAmount,
        paymentMethodId: currentPaymentMethod!.id,
        deviceType: "desktop",
        recurringId: currentPaymentMethod!.recurringId!,
        withdrawRequestPayload: {
          fields: additionalFieldsData,
        },
      },
      {
        onSuccess: () => {
          setDialogData({ type: "success", currency: currentAccount.currency!, value: currentAmount });

          // Google Analytics
          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            user_id: profile.id,
            client_id: clientId,
            ec: "attempt_withdraw",
            ea: "no_error",
            el: "no_error",
            ga4_event_name: "attempt_withdraw",
          });
        },
        onError: ({ errorMessage, code }) => {
          setDialogData({ type: "error", errorMessage });

          // Google Analytics
          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            user_id: profile.id,
            client_id: clientId,
            ec: "attempt_withdraw",
            ea: "error",
            el: code,
            ga4_event_name: "attempt_withdraw",
          });

          logError(errorMessage);
        },
      },
    );
  }, [additionalFieldsData, clientId, currentAccount, currentAmount, currentPaymentMethod, profile.id, withdrawal]);

  return (
    <>
      <ScrollRestoration key={currentStep} />

      {currentStep === "paymentMethod" &&
        (paymentMethodsData.withdrawOptions === PaymentMethodsWithdrawOptions.LowBalance ? (
          <WithdrawalNoAccounts paymentMethods={paymentMethodsData.items!} />
        ) : (
          <PaymentMethodStepContainer
            account={currentAccount}
            paymentMethodsData={paymentMethodsData}
            currentPaymentMethod={currentPaymentMethod}
            next={nextFromPaymentMethod}
            back={backFromPaymentMethod}
            setPaymentMethod={setCurrentPaymentMethod}
          />
        ))}

      {currentStep === "amount" && (
        <AmountStepContainer
          currentAmount={currentAmount}
          currentAccount={currentAccount}
          currentPaymentMethod={currentPaymentMethod!}
          back={backFromAmount}
          next={nextFromAmount}
          setCurrentAmount={setCurrentAmount}
        />
      )}

      {currentStep === "additionalFields" && (
        <AdditionalFieldsStep
          currentPaymentMethod={currentPaymentMethod!}
          back={backFromAdditionalFields}
          next={nextFromAdditionalFields}
          setAdditionalFieldsData={setAdditionalFieldsData}
        />
      )}
      {currentStep === "check" && (
        <CheckStepContainer
          additionalFieldsData={additionalFieldsData}
          back={backFromCheck}
          currentAccount={currentAccount}
          currentAmount={currentAmount}
          currentPaymentMethod={currentPaymentMethod!}
          onSubmit={onSubmit}
          submitIsloading={isLoading}
        />
      )}

      <StatusDialog dialogData={dialogData} setDialogData={setDialogData} />
    </>
  );
};

export { WithdrawalOptionsForm };
