import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { useControlledField } from "@/app/form";
import { useTranslation } from "@/hooks/translator.hook";
import { SurveyField } from "@/services/openapi";
import { RadioGroup } from "@/shared/ui";
import { Text } from "@/shared/ui";

import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";

type Props = {
  field: SurveyField;
};

export const SurveyBooleanField: FC<Props> = ({
  field: { title, id, type, value, errorMessage: errorMessageProps },
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const text = generateSurveyFieldTitle(title!);
  const [field, { errorMessage }] = useControlledField({
    name: generateSurveyFieldId(id!, type!),
    defaultValue: value,
    control,
    rules: {
      required: errorMessageProps ?? t("form-errors.required-error")!,
    },
  });

  return (
    <>
      <Text variant="M / Regular">{text}</Text>

      <RadioGroup value={field.value} onValueChange={field.onChange} name={field.name}>
        <RadioGroup.Item label={t("common.yes")} value={"true"} />
        <RadioGroup.Item label={t("common.no")} value={"false"} />
      </RadioGroup>

      {errorMessage && (
        <Text variant="S Compact / Regular" color="negative">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
