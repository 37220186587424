import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonGroup, Text } from "@/shared/ui";

import { BankCardTable } from "./bank-card-table";
import { CryptoTable } from "./crypto-table";
import { OtherTable } from "./other-table";
import { WithdrawalCardTableWrapperProps } from "./types";

enum Types {
  BANK = "bank",
  CRYPTO = "crypto",
  OTHER = "other",
}

const InfoBlock: FC<WithdrawalCardTableWrapperProps> = ({ cryptoInfo, bankCardInfo, otherInfo }) => {
  const { t } = useTranslation();
  const [type, setType] = useState<Types>(Types.BANK);

  return (
    <>
      <Text as="h3" className="mb-6 text-center" color="primary" variant="XL / Medium">
        {t("withdrawal.how-to-dialog.info.title")}
      </Text>

      <ButtonGroup value={type} onValueChange={setType} size="sm" className="my-6">
        <ButtonGroup.Item value={Types.BANK}>{t("withdrawal.how-to-dialog.info.variants.bank-card")}</ButtonGroup.Item>
        <ButtonGroup.Item value={Types.CRYPTO}>{t("withdrawal.how-to-dialog.info.variants.crypto")}</ButtonGroup.Item>
        <ButtonGroup.Item value={Types.OTHER}>{t("withdrawal.how-to-dialog.info.variants.other")}</ButtonGroup.Item>
      </ButtonGroup>

      {type === Types.BANK && <BankCardTable {...bankCardInfo} />}
      {type === Types.CRYPTO && <CryptoTable {...cryptoInfo} />}
      {type === Types.OTHER && <OtherTable {...otherInfo} />}
    </>
  );
};

export { InfoBlock };
