import { FC } from "react";
import { Link } from "react-router-dom";

import { Text } from "@/components/text";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import { useTranslation } from "@/hooks/translator.hook";
import { Button } from "@/shared/ui";

type Props = {
  isSurveyCompleted: boolean;
};

export const VerificationButton: FC<Props> = ({ isSurveyCompleted }) => {
  const { t } = useTranslation();
  const verificationRoute = getVerificationRoute(isSurveyCompleted);

  return (
    <>
      <Text family="roboto" lineHeight="3" css={{ mt: "24px", mb: "16px" }}>
        {t("profile-settings.personal.fill-data")}
      </Text>
      <Button variant="tertiary" size="sm" asChild>
        <Link to={verificationRoute}>{t("button.go-to-verification")}</Link>
      </Button>
    </>
  );
};
