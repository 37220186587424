import { FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { DetailsTable as Table } from "@/components/details-table";

import { WithdrawalCardTableProps } from "./types";

const BankCardTable: FC<WithdrawalCardTableProps> = ({
  processingTime,
  decimalScale,
  currency,
  limits: { maxWithdraw, minWithdraw },
}) => {
  const { t } = useTranslation();

  return (
    <Table.Card>
      <Table>
        <Table.Row>
          <Table.Label>{t("withdrawal.how-to-dialog.info.tables.labels.min")}</Table.Label>
          <Table.Value>
            <NumberFormat value={minWithdraw} decimalScale={decimalScale} currency={currency} />
          </Table.Value>
        </Table.Row>
        <Table.Row>
          <Table.Label>{t("withdrawal.how-to-dialog.info.tables.labels.max")}</Table.Label>
          <Table.Value>
            <NumberFormat value={maxWithdraw} decimalScale={decimalScale} currency={currency} />
          </Table.Value>
        </Table.Row>
        <Table.Row>
          <Table.Label>{t("withdrawal.how-to-dialog.info.tables.labels.time")}</Table.Label>
          <Table.Value>{processingTime}</Table.Value>
        </Table.Row>
        <Table.Row>
          <Table.Label>{t("withdrawal.how-to-dialog.info.tables.labels.comission")}</Table.Label>
          <Table.Value>{t("withdrawal.how-to-dialog.info.tables.values.bank-card.comission")}</Table.Value>
        </Table.Row>
      </Table>
    </Table.Card>
  );
};

export { BankCardTable };
