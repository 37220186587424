import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import { Checkbox } from "@/shared/ui";

import { AddressFields } from "./profile.form";

const NotUSACitizenField: FC = () => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const [isNotUSAResidentField, { invalid: isNotUSAResidentInvalid }] = useControlledField({
    name: AddressFields.IS_NOT_USA_RESIDENT,
    control,
    rules: {
      required: true,
    },
  });

  return (
    <Checkbox
      label={t("onboarding.address.us-citizen-checkbox")}
      invalid={isNotUSAResidentInvalid}
      checked={isNotUSAResidentField.value}
      onCheckedChange={isNotUSAResidentField.onChange}
      name={isNotUSAResidentField.name}
    />
  );
};

export { NotUSACitizenField };
