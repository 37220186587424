import { FC, ReactNode, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { toggleLiveChat } from "@/app/libs/liveChat";
import { IconGlassCheckSmall, IconGlassCrossSmall } from "@/domains/ui-kit";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Button, Dialog, WideDialogContent } from "@/shared/ui";

type DialogDataError = {
  type: "error";
  errorMessage: ReactNode;
};

type DialogDataSuccess = {
  type: "success";
  value: string;
  currency: string;
};

export type DialogData = {
  type: "error" | "success";
} & (DialogDataError | DialogDataSuccess);

type Props = {
  dialogData: DialogData | null;
  setDialogData: SetState<DialogData | null>;
};

const StatusDialog: FC<Props> = ({ dialogData, setDialogData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isSuccess = dialogData?.type === "success";
  const icon = isSuccess ? (
    <IconGlassCheckSmall className="size-[72px]" />
  ) : (
    <IconGlassCrossSmall className="size-[72px]" />
  );
  const title = isSuccess ? t("withdrawal.status-dialog.success-title") : t("withdrawal.status-dialog.error-title");
  const description = isSuccess ? (
    <Trans
      i18nKey={"withdrawal.status-dialog.success-description"}
      values={{
        value: dialogData.value,
        symbol: dialogData.currency,
      }}
    />
  ) : (
    t("withdrawal.status-dialog.error-description")
  );
  const isOpen = Boolean(dialogData?.type);

  const onClose = useCallback(() => {
    if (dialogData?.type === "success") {
      setDialogData(null);
      navigate(cabinetRoutes.dashboard, { replace: true });
    }

    if (dialogData?.type === "error") {
      setDialogData(null);
    }
  }, [dialogData?.type, navigate, setDialogData]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <WideDialogContent icon={icon} title={title} description={description}>
        <div className="flex w-full flex-col gap-2">
          {isSuccess ? (
            <Button size="md" variant="secondary" onClick={onClose}>
              {t("button.got-it")}
            </Button>
          ) : (
            <>
              <Button size="md" onClick={onClose} variant="secondary">
                {t("button.try-again")}
              </Button>
              <Button
                size="md"
                variant="tertiary"
                onClick={() => {
                  onClose();
                  toggleLiveChat();
                }}
              >
                {t("button.contact-support")}
              </Button>
            </>
          )}
        </div>
      </WideDialogContent>
    </Dialog>
  );
};

export { StatusDialog };
