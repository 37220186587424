import { memo, type ReactNode } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";

type Props<T extends FieldValues> = {
  /**
   * form object from `useHookForm`
   */
  form: UseFormReturn<T>;
  /**
   * Provide a function returning promise to handle isSubmitting logic for `SubmitButton` component
   */
  onSubmit?: SubmitHandler<T>;
  children?: ReactNode;
  className?: string;
  onInvalid?: SubmitErrorHandler<T>;
};

const _HookForm = <T extends FieldValues>({ children, form, onSubmit, className, onInvalid }: Props<T>) => {
  return (
    <FormProvider {...form}>
      <form
        className={className}
        noValidate
        onSubmit={
          onSubmit
            ? form.handleSubmit(
                async (data, event) => {
                  try {
                    await onSubmit(data, event);
                  } catch (e) {}
                },

                onInvalid,
              )
            : (e: React.FormEvent) => {
                e.preventDefault();
              }
        }
      >
        {children}
      </form>
    </FormProvider>
  );
};

const HookForm = memo(_HookForm) as typeof _HookForm;

const useHookForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>,
) => {
  return useForm({ mode: "onTouched", shouldUnregister: true, ...props });
};

export { HookForm, useHookForm };
