import { FC } from "react";
import { useTranslation } from "react-i18next";

import { DialogButtons, DialogContent } from "@/components/dialog";
import { IconCross } from "@/domains/icons";
import { Dialog, NewButton, Text } from "@/shared/ui";

type Props = {
  opened: boolean;
  onOpenChange: (open: boolean) => void;
};

const Component: FC<Props> = ({ opened, onOpenChange }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={opened} onOpenChange={onOpenChange}>
      <DialogContent size="md">
        <div className="mx-auto mb-6 flex size-[56px] items-center justify-center rounded-full bg-negative-bg text-text-inverse">
          <IconCross height={50} width={50} />
        </div>

        <Dialog.Title asChild>
          <Text color="primary" variant="XL / Medium" className="text-center">
            {t("onboarding.country-dialog.title")}
          </Text>
        </Dialog.Title>
        <Dialog.Description asChild>
          <Text color="primary" variant="M / Regular" className="mt-6 text-center">
            {t("onboarding.country-dialog.text")}
          </Text>
        </Dialog.Description>
        <DialogButtons>
          <Dialog.Close asChild>
            <NewButton fullWidth className="mx-auto">
              {t("button.got-it")}
            </NewButton>
          </Dialog.Close>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  );
};

export { Component as InvalidCountryDialog };
