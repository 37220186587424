import React, { FC } from "react";

import { cn } from "@/shared/styles";
import { Text } from "@/shared/ui";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const ErrorMessage: FC<Props> = ({ className, children }) => {
  return (
    <Text variant="S Compact / Regular" color="negative" className={cn("mt-2", className)}>
      {children}
    </Text>
  );
};
