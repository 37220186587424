import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, ReactNode } from "react";

import { IconCross } from "@/domains/icons";
import { cn } from "@/shared/styles";
import { Dialog, overlayStyles, Text } from "@/shared/ui";

// TODO: remove the component

const CloseButton = () => (
  <Dialog.Close className="text-contrast-primary outline-none">
    <IconCross />
  </Dialog.Close>
);

type Props = ComponentPropsWithoutRef<typeof Dialog.Content> & {
  title: ReactNode;
  description?: ReactNode;
  image?: {
    src: string;
    alt?: string;
  };
};

const NewDialogContent = forwardRef<ElementRef<typeof Dialog.Content>, Props>(
  ({ children, title, description, image, ...props }, forwardedRef) => {
    return (
      <Dialog.Portal>
        <Dialog.Overlay className={cn(overlayStyles(), "grid place-items-center overflow-y-auto overflow-x-hidden")}>
          <Dialog.Content
            className="relative z-50 h-full w-full bg-bg px-4 pb-4 shadow outline-none md:mb-[30px] md:mt-[5vh] md:h-auto md:max-w-[512px] md:rounded-[24px] md:p-14"
            {...props}
            ref={forwardedRef}
          >
            {image && (
              <div className="mx-auto mb-6 flex justify-center">
                <img alt={image.alt} src={image.src} />
              </div>
            )}

            <div className="mb-4 md:hidden">
              <div className="flex items-center justify-between py-4">
                <Dialog.Title asChild>
                  <Text as="h2" color="primary" variant="2XL / Medium">
                    {title}
                  </Text>
                </Dialog.Title>
                <CloseButton />
              </div>
              {description && (
                <Dialog.Description asChild>
                  <Text color="primary" variant="M / Regular" className="mt-2">
                    {description}
                  </Text>
                </Dialog.Description>
              )}
            </div>

            <div className="mb-8 hidden md:block">
              <Dialog.Title asChild>
                <Text as="h2" color="primary" align="center" variant="2XL / Medium">
                  {title}
                </Text>
              </Dialog.Title>
              {description && (
                <Dialog.Description asChild>
                  <Text color="primary" align="center" variant="M / Regular" className="mt-4">
                    {description}
                  </Text>
                </Dialog.Description>
              )}
              <div className="absolute end-6 top-6" data-test="dialog-close">
                <CloseButton />
              </div>
            </div>
            {children}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    );
  },
);

export { NewDialogContent };
