import { FC } from "react";
import { Outlet } from "react-router-dom";

export const OnboardingLayout: FC = () => {
  return (
    <div className="relative min-h-[100%] bg-white">
      <Outlet />
    </div>
  );
};
