import { useTranslation } from "react-i18next";

import { PaymentProcessingTime } from "@/services/openapi";

const useProcessingTimeTranslation = (paymentProcessingTime: PaymentProcessingTime): string => {
  const { t } = useTranslation();

  switch (paymentProcessingTime) {
    case PaymentProcessingTime.Instant:
      return t("duration.instant");
    case PaymentProcessingTime.FifteenMinutes:
      return t("duration.fifteen-minutes");
    case PaymentProcessingTime.OneDay:
      return t("duration.one-day");
    case PaymentProcessingTime.ThreeHours:
      return t("duration.three-hours");
    case PaymentProcessingTime.ThreeDays:
      return t("duration.three-days");

    case PaymentProcessingTime.None:
      return "";
  }
};

export { useProcessingTimeTranslation };
