import { type FC, type ReactNode } from "react";

import { IconArrowLeft } from "@/domains/icons";
import { Loader as UILoader, Text } from "@/shared/ui";

const Footer: FC<{ children: ReactNode; offset?: "sm" | "md" }> = ({ children, offset = "md" }) => {
  return <div className={offset === "md" ? "mt-8" : "mt-6"}>{children}</div>;
};

const Loader: FC = () => {
  return <UILoader />;
};

type Props = {
  title: ReactNode;
  children: ReactNode;
  description?: ReactNode;
  onBack?: () => void;
};

const PaymentWrapper: FC<Props> = ({ title, description, children, onBack }) => {
  const titleElement = (
    <Text variant="M / Medium" color="primary" as="h3">
      {title}
    </Text>
  );

  return (
    <div className="mt-4 lg:max-w-[400px]">
      <div className="mb-6">
        {onBack ? (
          <button className="flex items-center gap-2 outline-none" onClick={onBack}>
            <IconArrowLeft />
            {titleElement}
          </button>
        ) : (
          titleElement
        )}
      </div>
      {description && (
        <div className="mb-6">
          <Text variant="S / Regular" color="secondary">
            {description}
          </Text>
        </div>
      )}
      {children}
    </div>
  );
};

const Component = Object.assign(PaymentWrapper, { Footer, Loader });

export { Component as PaymentWrapper };
