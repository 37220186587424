import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { PaymentWrapper } from "@/features/payment/ui/wrapper/index";
import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { BonusUserPlatform, PaymentMethod, TradingAccount } from "@/services/openapi";
import { useDepositFeeQuery, usePaymentInfo } from "@/state/server/payment";
import { useRateQuery } from "@/state/server/rate";

import { CheckStep } from "./step";

type Props = {
  submitIsloading: boolean;
  currentAccount: TradingAccount;
  currentPaymentMethod: PaymentMethod;
  currentAmount: string;
  bonus: BonusUserPlatform | null;
  isBonusAccepted: boolean;
  paymentLink: string | undefined;
  back: () => void;
  onSubmit: () => void;
  setIsBonusAccepted: (value: boolean) => void;
};

const CheckStepContainer: FC<Props> = ({
  submitIsloading,
  currentPaymentMethod,
  back,
  bonus,
  currentAccount,
  currentAmount,
  isBonusAccepted,
  paymentLink,
  onSubmit,
  setIsBonusAccepted,
}) => {
  const { t } = useTranslation();

  const { data: paymentsInfo } = usePaymentInfo();

  const { data: fee } = useDepositFeeQuery({
    paymentMethodId: currentPaymentMethod.id,
    accountId: currentAccount.id,
    amount: currentAmount,
  });

  const { data: rate, isIdle: rateIsIdle } = useRateQuery(
    { from: bonus?.currency!, to: currentAccount.currency! },
    { enabled: !!bonus },
  );

  return (
    <PaymentWrapper title={t("deposit.form.amount.title")} onBack={back}>
      {fee && (rate || rateIsIdle) && paymentsInfo ? (
        <CheckStep
          sourceOfFundsLimits={paymentsInfo.depositInfo!.sourceOfFundsLimits!}
          paymentLink={paymentLink}
          submitIsloading={submitIsloading}
          rate={rate}
          bonus={bonus}
          feeValue={fee.value!}
          currentAmount={getInputNumberValue(currentAmount)!}
          currentAccount={currentAccount}
          isBonusAccepted={isBonusAccepted}
          currentPaymentMethod={currentPaymentMethod}
          setIsBonusAccepted={setIsBonusAccepted}
          onSubmit={onSubmit}
        />
      ) : (
        <PaymentWrapper.Loader />
      )}
    </PaymentWrapper>
  );
};

export { CheckStepContainer };
