import { FC, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { IconInfo } from "@/domains/icons";
import { PaymentMethodToggleGroup } from "@/features/payment/ui/method-toggle-group";
import { PaymentWrapper } from "@/features/payment/ui/wrapper";
import { HowToDialog } from "@/features/payment/withdrawal/components/dialogs/how-to-dialog/how-to-dialog";
import { WithdrawalCardTableProps } from "@/features/payment/withdrawal/components/dialogs/how-to-dialog/types";
import { PaymentInfo, PaymentMethod, TradingAccount } from "@/services/openapi";
import { Dialog, NewButton, Text } from "@/shared/ui";

import { useProcessingTimeTranslation } from "./processing-time.hook";

type Props = {
  currentAccount: TradingAccount;
  setPaymentMethod: (method: PaymentMethod) => void;
  paymentInfo: PaymentInfo;
  paymentMethods: PaymentMethod[];
  next: () => void;
  currentPaymentMethod: PaymentMethod | null;
};

const PaymentMethodStep: FC<Props> = ({
  paymentInfo,
  currentPaymentMethod,
  currentAccount,
  paymentMethods,
  next,
  setPaymentMethod,
}) => {
  const { t } = useTranslation();

  const bankInfo: WithdrawalCardTableProps = {
    limits: paymentInfo?.limitsAndWithdrawTime!.bankCard!.limits!.find(
      ({ currency }) => currency === currentAccount.currency,
    )!,
    processingTime: useProcessingTimeTranslation(paymentInfo.limitsAndWithdrawTime!.bankCard!.processingTime!),
    decimalScale: currentAccount.digits!,
    currency: currentAccount.currency!,
  };
  const cryptoInfo: WithdrawalCardTableProps = {
    limits: paymentInfo.limitsAndWithdrawTime!.crypto!.limits!.find(
      ({ currency }) => currency === currentAccount.currency,
    )!,
    processingTime: useProcessingTimeTranslation(paymentInfo.limitsAndWithdrawTime!.crypto!.processingTime!),
    decimalScale: currentAccount.digits!,
    currency: currentAccount.currency!,
  };
  const otherInfo: WithdrawalCardTableProps = {
    limits: paymentInfo.limitsAndWithdrawTime!.other!.limits!.find(
      ({ currency }) => currency === currentAccount.currency,
    )!,
    processingTime: useProcessingTimeTranslation(paymentInfo.limitsAndWithdrawTime!.other!.processingTime!),
    decimalScale: currentAccount.digits!,
    currency: currentAccount.currency!,
  };

  const onValueChange = useCallback(
    (value: string) => {
      const method = paymentMethods.find(({ title }) => title === value)!;
      setPaymentMethod(method);
    },
    [paymentMethods, setPaymentMethod],
  );

  return (
    <>
      <Text variant="S / Regular" color="primary" className="mb-4">
        {t("payments.payment-method")}
      </Text>

      <Dialog>
        {/* TODO: use Alert component */}
        <div className="mb-6 flex gap-3 rounded-[16px] border border-control-border bg-contrast-quinary p-4">
          <div className="size-6 text-contrast-secondary">
            <IconInfo />
          </div>
          <Text variant="S Compact / Regular" color="secondary">
            <Trans
              i18nKey={"withdrawal.form.info.prev-method"}
              components={{
                learn: <Dialog.Trigger className="underline" />,
              }}
            />
          </Text>
        </div>
        <HowToDialog bankCardInfo={bankInfo} cryptoInfo={cryptoInfo} otherInfo={otherInfo} />
      </Dialog>

      <PaymentMethodToggleGroup value={currentPaymentMethod?.title || ""} onValueChange={onValueChange}>
        {paymentMethods.map(({ title, images, details, currency }) => {
          const limits = details!.limits?.find(limit => limit.currency === currency);

          return (
            <PaymentMethodToggleGroup.Item
              key={title}
              value={title!}
              title={title}
              images={images!}
              description={
                limits?.platformMax ? (
                  <Trans
                    i18nKey={"withdrawal.form.up-to"}
                    components={{
                      amount: (
                        <NumberFormat
                          value={limits.platformMax}
                          currency={currency!}
                          decimalScale={currentAccount.digits}
                        />
                      ),
                    }}
                  />
                ) : (
                  void 0
                )
              }
            />
          );
        })}
      </PaymentMethodToggleGroup>
      <PaymentWrapper.Footer>
        <NewButton fullWidth disabled={!currentPaymentMethod} onClick={next}>
          {t("button.next")}
        </NewButton>
      </PaymentWrapper.Footer>
    </>
  );
};

export { PaymentMethodStep };
