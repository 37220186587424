import Big from "big.js";

import { PaymentProcessingTime } from "@/services/openapi";
import { BonusUserPlatform } from "@/services/openapi";

const calculateBonus = (amountValue: string | number, bonusInfo: BonusUserPlatform) => {
  const amount = Number(amountValue);
  const bonusAmounts = bonusInfo?.amountSet?.toSorted((a, b) => b.amount! - a.amount!);

  if (!bonusAmounts) return 0;
  if (amount === 0) {
    for (const bonus of bonusAmounts) {
      const { amount, limitMaxCurrent } = bonus;

      if (Number(limitMaxCurrent) !== 0) {
        return Number(amount);
      }
    }
  }

  if (amount < 0) {
    return bonusAmounts?.length > 0 ? bonusAmounts[0].amount! : 0;
  }

  let remainingAmount = new Big(amount);
  let res = new Big(0);

  for (const bonus of bonusAmounts) {
    const coefficient = new Big(bonus.amount!).div(100);

    if (amount <= bonus.limitMaxCurrent!) {
      res = res.plus(remainingAmount.mul(coefficient));
      break;
    }

    res = res.plus(new Big(bonus.limitMaxCurrent!).mul(coefficient));
    remainingAmount = remainingAmount.minus(bonus.limitMaxCurrent!);
  }

  if (res.toNumber() === 0) return 0;
  else return res.div(amount).mul(100).toNumber();
};

export { calculateBonus };
