import { VariantProps } from "@stitches/react";
import { FC, ReactNode } from "react";
import { FieldError } from "react-hook-form";

import { Text } from "@/components/text";
import { styled } from "@/styles/stitches.config";
import { CSS } from "@/styles/types";

import { Description, Error, Label } from "../styles";

const StyledInputWrapper = styled("div", {
  variants: {
    bottomOffset: {
      "0": {
        mb: "0px",
      },
      "1": {
        mb: "8px",
      },
      "2": {
        mb: "12px",
      },
      "3": {
        mb: "16px",
      },
      "4": {
        mb: "24px",
      },
      "5": {
        mb: "32px",
      },
      "6": {
        mb: "40px",
      },
    },
  },

  defaultVariants: {
    bottomOffset: "0",
  },
});

const InputContainer = styled("div", {
  display: "flex",
  alignItems: "center",

  background: "rgba(211, 213, 222, 0.3)",
  borderRadius: "8px",
  border: "1px solid",
  borderColor: "$bgBorder",

  "&:hover": {
    borderColor: "$textSecondary",
  },

  "&:focus-within": {
    borderColor: "$textMain",
  },

  "@bp3": {
    borderRadius: "16px",
  },

  variants: {
    border: {
      error: {
        borderColor: "$negativeDefault",
      },
      warning: {
        borderColor: "$warningDefault",
      },
      success: {
        borderColor: "$positiveDefault",
      },
    },
    disabled: {
      true: {
        borderColor: "$bgBorder !important",
      },
    },
    size: {
      small: {
        p: "8px 16px",
      },
      middle: {
        p: "15px 15px",
      },
    },
  },

  defaultVariants: {
    size: "middle",
  },
});

const AdornmentWrapper = styled("div", {
  display: "flex",
  variants: {
    position: {
      left: {
        marginRight: "20px",
      },
      right: {
        marginLeft: "20px",
      },
    },
  },
});

export type PasswordBorderType = VariantProps<typeof InputContainer>["border"];

interface InputWrapperInternalProps {
  children: ReactNode;
}

export type InputWrapperExternalProps = VariantProps<typeof StyledInputWrapper> &
  VariantProps<typeof InputContainer> & {
    css?: CSS;
    label?: ReactNode;
    leftAdornment?: ReactNode;
    rightAdornment?: ReactNode;
    error?: FieldError;
    showError?: boolean;
    name?: string;
    disabled?: boolean;
    description?: string;
    passwordText?: string;
  };

type Props = InputWrapperExternalProps & InputWrapperInternalProps;

export const InputWrapper: FC<Props> = ({
  bottomOffset,
  css,
  showError,
  leftAdornment,
  rightAdornment,
  error,
  name,
  label,
  children,
  disabled,
  size,
  description,
  border,
  passwordText,
}) => {
  return (
    <StyledInputWrapper css={css} bottomOffset={bottomOffset}>
      {label && <Label label={label} name={name} />}
      {description && <Description description={description} />}
      <InputContainer border={showError ? "error" : border} disabled={disabled} size={size}>
        {leftAdornment && <AdornmentWrapper position="left">{leftAdornment}</AdornmentWrapper>}
        {children}
        {rightAdornment && <AdornmentWrapper position="right">{rightAdornment}</AdornmentWrapper>}
      </InputContainer>
      {passwordText ? (
        <Text
          family="roboto"
          color={border === "warning" ? "warning" : border === "success" ? "positive" : "negative"}
          css={{ mt: "8px" }}
          size={{ "@initial": "1", "@bp3": "2" }}
        >
          {passwordText}
        </Text>
      ) : (
        showError && <Error errorMessage={error!.message} />
      )}
    </StyledInputWrapper>
  );
};
