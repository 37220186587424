import {
  PaymentsApiGetDepositFeeRequest,
  PaymentsApiGetMaximumDepositAmountRequest,
  PaymentsApiGetMaximumWithdrawAmountRequest,
  PaymentsApiGetPaymentMethodsRequest,
  PaymentsApiGetWithdrawFeeRequest,
  PaymentsApiGetWithdrawMethodsRequest,
  PaymentsApiMakeDepositRequest,
  PaymentsApiMakeWithdrawRequest,
} from "@/services/openapi";

import { paymentService } from "../api";

export const getDepositMethods = async (values?: PaymentsApiGetPaymentMethodsRequest) =>
  await (
    await paymentService().getPaymentMethods(values)
  ).data;

export const getWithdrawMethods = async (values?: PaymentsApiGetWithdrawMethodsRequest) =>
  await (
    await paymentService().getWithdrawMethods(values)
  ).data;

export const getDepositFee = async (values: PaymentsApiGetDepositFeeRequest) =>
  await (
    await paymentService().getDepositFee(values)
  ).data;

export const getWithdrawFee = async (values: PaymentsApiGetWithdrawFeeRequest) =>
  await (
    await paymentService().getWithdrawFee(values)
  ).data;

export const getMaximumWithdrawAmount = async (values: PaymentsApiGetMaximumWithdrawAmountRequest) =>
  await (
    await paymentService().getMaximumWithdrawAmount(values)
  ).data;

export const makeDeposit = async (values: PaymentsApiMakeDepositRequest) =>
  await (
    await paymentService().makeDeposit(values)
  ).data;

export const makeWithdraw = async (values: PaymentsApiMakeWithdrawRequest) =>
  await (
    await paymentService().makeWithdraw(values)
  ).data;

export const getDepositWidgets = async () => await (await paymentService().getDepositWidgets()).data;

export const getWithdrawWidgets = async () => await (await paymentService().getWithdrawWidgets()).data;
export const getPaymentInfo = async () => await (await paymentService().getPaymentInfo()).data;

export const getMaximumDepositAmount = async (values: PaymentsApiGetMaximumDepositAmountRequest) =>
  await (
    await paymentService().getMaximumDepositAmount(values)
  ).data;
