import { Navigate, useParams } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountStatus, TradingAccountType } from "@/services/openapi";
import { Loader } from "@/shared/ui";
import { useAccountQuery } from "@/state/server/accounts";
import { useWithdrawMethodQuery } from "@/state/server/payment";

import { WithdrawalOptionsForm } from "./components/form/withdrawal-options.form";

export const WithdrawalOptionsPage = () => {
  const { id: currentAccountId } = useParams<{ id: string }>();

  const { data: account } = useAccountQuery(currentAccountId!);

  const { data: paymentMethodsData } = useWithdrawMethodQuery({ accountId: account?.id }, { enabled: !!account });

  if (!account || !paymentMethodsData) {
    return <Loader />;
  }

  if (account.type !== TradingAccountType.Real || account.status !== TradingAccountStatus.Active) {
    return <Navigate to={cabinetRoutes.withdrawal} replace />;
  }

  return <WithdrawalOptionsForm currentAccount={account} paymentMethodsData={paymentMethodsData} />;
};
