import { type FC } from "react";
import { Navigate } from "react-router-dom";

import { DepositNoAccounts } from "@/features/payment/deposit/components/no-accounts";
import { getDepositRoute } from "@/routes/cabinet.routes";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { Loader } from "@/shared/ui";
import { useAllAccountsQuery, useLastAccountQuery } from "@/state/server/accounts";
import { useDepositMethodQuery } from "@/state/server/payment";

import { DepositForm } from "./components/form/deposit.form";

const DepositPage: FC = () => {
  const { data: accounts } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    sorting: TradingAccountSorting.Newest,
    status: AvailabilityStatus.Active,
  });
  const { data: allPaymentMethods } = useDepositMethodQuery();

  const { data: lastAccount } = useLastAccountQuery({
    type: TradingAccountType.Real,
    status: AvailabilityStatus.Active,
  });

  if (!accounts || !allPaymentMethods || !lastAccount) {
    return <Loader />;
  }

  if (!accounts.length) {
    return <DepositNoAccounts paymentMethods={allPaymentMethods.items!} />;
  }

  if (accounts.length === 1) {
    return <Navigate to={getDepositRoute(accounts[0].id!)} replace />;
  }

  return <DepositForm accounts={accounts} lastAccountId={lastAccount.id!} />;
};

export { DepositPage };
