import { type FC } from "react";

import { Skeleton } from "@/shared/ui";

const ChartSymbolsSkeleton: FC = () => (
  <div className="flex gap-2 overflow-auto lg:flex-row-reverse lg:justify-end">
    <div className="flex flex-row-reverse gap-2 overflow-auto scrollbar-hide">
      <Skeleton className="h-12 w-[100px] rounded-[16px] lg:h-[54px] lg:w-[176px]" />
      <Skeleton className="h-12 w-[100px] rounded-[16px] lg:h-[54px] lg:w-[176px]" />
      <Skeleton className="h-12 w-[100px] rounded-[16px] lg:h-[54px] lg:w-[176px]" />
    </div>
    <Skeleton className="h-12 w-12 rounded-[12px] lg:h-[54px] lg:w-10" />
  </div>
);

export { ChartSymbolsSkeleton };
