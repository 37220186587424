import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { BonusUserPlatform } from "@/services/openapi";
import { Button, Dialog, WideDialogContent } from "@/shared/ui";

import bonusImg from "../assets/promo.png";
import { getBonusMainPercent } from "../helpers";
import { ExampleTable } from "./example-table";
import { TermsBlock } from "./terms-block";

type Props = {
  bonus: BonusUserPlatform;
};

const BonusDialog: FC<Props> = ({ bonus }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const mainPercent = useMemo(() => getBonusMainPercent(bonus), [bonus]);
  const isDepoitPage = location.pathname.includes(cabinetRoutes.deposit);

  return (
    <WideDialogContent
      image={bonusImg}
      title={t("bonus.dialog.title", { value: mainPercent })}
      description={
        <Trans
          i18nKey="bonus.dialog.description"
          values={{ value: mainPercent }}
          components={{
            strong: <WideDialogContent.DescriptionStrong />,
          }}
        />
      }
      footer={
        <>
          {isDepoitPage ? (
            <Dialog.Close asChild>
              <Button variant="secondary" className="w-full">
                {t("button.deposit")}
              </Button>
            </Dialog.Close>
          ) : (
            <Dialog.Close asChild>
              <Button variant="secondary" className="w-full" asChild>
                <Link to={cabinetRoutes.deposit}>{t("button.deposit")}</Link>
              </Button>
            </Dialog.Close>
          )}
        </>
      }
      scrollAreaClassname="mb-[72px] md:mb-[120px]"
    >
      <ExampleTable currency={bonus.currency!} mainPercent={mainPercent!} />
      <Dialog.Separator />
      <TermsBlock bonus={bonus} />
    </WideDialogContent>
  );
};

export { BonusDialog };
