import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { PaymentMethod } from "@/services/openapi";

import { PaymentNoAccounts } from "../../components/no-accounts-state";
import { PaymentEmptyState } from "../../ui/empty-state";

type Props = {
  paymentMethods: PaymentMethod[];
};

const WithdrawalNoAccounts: FC<Props> = ({ paymentMethods }) => {
  const { t } = useTranslation();

  return (
    <PaymentNoAccounts paymentMethods={paymentMethods}>
      <PaymentEmptyState title={t("withdrawal.low-balance.text")}>
        <PaymentEmptyState.Button asChild>
          <Link to={cabinetRoutes.dashboard}>{t("withdrawal.low-balance.button")}</Link>
        </PaymentEmptyState.Button>
      </PaymentEmptyState>
    </PaymentNoAccounts>
  );
};

export { WithdrawalNoAccounts };
