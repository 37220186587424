import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { HookForm, useControlledField, useHookForm } from "@/app/form";
import { NewSubmitButton } from "@/app/form/new-submit-button";
import { PaymentWrapper } from "@/features/payment/ui/wrapper";
import { formatInputNumberValue, getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { MaximumLimitDescription, PaymentMethod, TradingAccount } from "@/services/openapi";
import { Chip, NewNumberInput, Text } from "@/shared/ui";

import { VerificationContainer } from "../../components/verification/verification.container";

enum Fields {
  AMOUNT = "amount",
}

type FormValues = {
  [Fields.AMOUNT]: string;
};

type Props = {
  withdrawLimit: MaximumLimitDescription;
  isKycCompleted: boolean;
  currentLimitBeforeKyc: number;
  currentAmount: string;
  amountSuggestions: number[];
  currentPaymentMethod: PaymentMethod;
  currentAccount: TradingAccount;
  next: () => void;
  setCurrentAmount: (value: string) => void;
};

const AmountStep: FC<Props> = ({
  withdrawLimit,
  currentAmount,
  amountSuggestions,
  currentLimitBeforeKyc,
  currentPaymentMethod,
  currentAccount,
  next,
  setCurrentAmount,
  isKycCompleted,
}) => {
  const { t } = useTranslation();

  const [isVerificationDialogVisible, { close: closeVerificationDialog, open: openVerificationDialog }] =
    useDisclosure();

  const decimalScale = currentAccount.digits!;
  const currency = currentAccount.currency!;
  const limit = useMemo(
    () => currentPaymentMethod.details!.limits?.find(item => item.currency === currency),
    [currency, currentPaymentMethod],
  );

  const limitMax = limit?.platformMax ? limit.platformMax : void 0;
  const limitMin = limit?.platformMin ? limit.platformMin : void 0;

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.AMOUNT]: formatInputNumberValue(currentAmount, decimalScale),
    },
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.AMOUNT,
    control,
    rules: { required: true, min: limitMin, max: limitMax },
  });

  const { amount } = watch();

  const errorType = errors[Fields.AMOUNT]?.type;

  const isMaxAmountError = errorType === "max";
  const isMinAmountError = errorType === "min" || errorType === "required";

  const numberAmount = getInputNumberValue(amount);

  const isMoreThanLimit = numberAmount && !isKycCompleted ? numberAmount > currentLimitBeforeKyc : false;

  const handleSubmit = ({ amount }: FormValues) => {
    setCurrentAmount(amount);

    if (!isKycCompleted) {
      openVerificationDialog();
      return;
    }

    next();
  };

  return (
    <>
      <HookForm form={form} onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          <Text variant="S / Regular" color="primary">
            {t("payments.amount")}
          </Text>
          {amountSuggestions.length > 0 && (
            <Chip.Group value={amount} onValueChange={value => setValue(Fields.AMOUNT, value)}>
              {amountSuggestions.map(value => (
                <Chip key={value} value={formatInputNumberValue(value, decimalScale)}>
                  <NumberFormat
                    value={value}
                    decimalScale={decimalScale}
                    fixedDecimalScale={false}
                    currency={currency}
                  />
                </Chip>
              ))}
            </Chip.Group>
          )}

          <NewNumberInput
            placeholder={`0 ${currency}`}
            currency={currency}
            decimalScale={decimalScale}
            pending={pending}
            invalid={invalid}
            descriptor={
              isMaxAmountError ? (
                <Trans
                  i18nKey="deposit.form.amount-step.max-error"
                  components={{
                    click: (
                      <NewNumberInput.DescriptorButton
                        onClick={() => setValue(Fields.AMOUNT, formatInputNumberValue(limitMax, decimalScale))}
                      />
                    ),
                    value: <NumberFormat value={limitMax} decimalScale={decimalScale} currency={currency} />,
                  }}
                />
              ) : isMinAmountError ? (
                <Trans
                  i18nKey="deposit.form.amount-step.min-error"
                  components={{
                    click: (
                      <NewNumberInput.DescriptorButton
                        onClick={() => setValue(Fields.AMOUNT, formatInputNumberValue(limitMin, decimalScale))}
                      />
                    ),
                    value: <NumberFormat value={limitMin} decimalScale={decimalScale} currency={currency} />,
                  }}
                />
              ) : null
            }
            {...field}
          />
        </div>
        <PaymentWrapper.Footer offset="sm">
          <NewSubmitButton fullWidth>{isMoreThanLimit ? t("button.go-to-verify") : t("button.next")}</NewSubmitButton>
        </PaymentWrapper.Footer>
      </HookForm>

      <VerificationContainer
        amount={Number(amount)}
        withdrawLimit={withdrawLimit}
        decimalScale={decimalScale}
        currency={currency}
        open={isVerificationDialogVisible}
        depositLimit={currentLimitBeforeKyc}
        submitDeposit={next}
        onClose={closeVerificationDialog}
      />
    </>
  );
};

export { AmountStep };
